/**
 * This page is cloned from the invoices page so it might
 * have similar naming conventions to the invoices page
 *
 */

import { useEffect, useState } from "react";
import { NavLink as Link } from "react-router-dom";
import { endpoints } from "../../hooks/endpoints";
import {
  dateFormatter,
  getToken,
  reorderForPagination,
} from "../../hooks/utils";
import { useAppSelector } from "../../store/hooks";
import Animation from "../Animation";
import Spinner from "../Spinner";

const TeacherSales = () => {
  const [allInvoices, setAllInvoices] = useState<any>(null);
  const [invoices, setInvoices] = useState<any>(null);
  const [currentCat, setCurrentCat] = useState<string>();
  const [currentLesson, setCurrentLesson] = useState<string>("all");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  const lessons = useAppSelector((state) => state.store.portal?.data);
  const cats = useAppSelector((state) => state.store.portal?.cats);

  const handleGetInvoices = async (e: any) => {
    e.preventDefault();
    if (!currentCat) return alert("يجب اختيار القسم");
    const endpoint =
      currentLesson === "all"
        ? `${endpoints.teacherSalesByCategory}/${currentCat}`
        : `${endpoints.teacherSalesByProduct}/${currentLesson}`;
    setIsPending(true);
    fetch(endpoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        const data = response.data as any;
        console.log("sales", data);
        setAllInvoices(data?.sort((a, b) => +b.order_id - +a.order_id));
        setCurrentPage(1);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => setIsPending(false));
  };

  useEffect(() => {
    if (allInvoices)
      setInvoices(
        reorderForPagination({
          arr: allInvoices,
          currentPage: currentPage,
          pageCount: 10,
        })
      );
  }, [currentPage, allInvoices]);

  useEffect(() => {
    if (invoices) {
      setInvoices(null);
    }
  }, [currentLesson]);

  // useEffect(() => {
  //   if (!invoices) {
  //     fetch(endpoints.teacherSales, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: getToken(),
  //       },
  //       method: "GET",
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         console.log("sales", data);
  //         const outputArray = [];
  //         for (const orderId in data.orders) {
  //           if (data.orders.hasOwnProperty(orderId)) {
  //             const order = data.orders[orderId];
  //             order["order_id"] = orderId;
  //             outputArray.push(order);
  //           }
  //         }
  //         const rev_arr = outputArray.reverse();
  //         setAllInvoices(rev_arr);
  //         setInvoices(rev_arr);
  //         setGroups(data.cat_lessons);
  //         setCurrentCat(data.cats);
  //         setCats(data.cats);
  //         setIsPending(false);
  //       })
  //       .catch((error) => {
  //         setError(error);
  //         setIsPending(false);
  //       });
  //   }
  // }, [invoicesStart]);

  return (
    <Animation>
      <div className="m-3 p-3 bg-white rounded-ea shadow">
        <div className="table-responsive">
          <h3 className="mt-3 mb-4 bg-ea-primary p-3 text-white rounded-ea shadow fw-bold">
            مبيعاتي
          </h3>
          <form onSubmit={handleGetInvoices}>
            <select
              onChange={(e) => setCurrentCat(e.target.value)}
              className="form-select w-75 my-2"
              aria-label="Default select example"
              required
            >
              <option value="" selected>
                القسم
              </option>
              {cats &&
                cats
                  .filter((cat) => cat.parent !== 0)
                  .map((cat) => (
                    <option value={cat.term_id}>
                      {cat.name.indexOf("|") !== -1
                        ? cat.name.split("|")[0]
                        : cat.name}
                    </option>
                  ))}
            </select>
            <select
              onChange={(e) => setCurrentLesson(e.target.value)}
              className="form-select w-75 my-2"
              aria-label="Default select example"
              required
            >
              <option value="all" selected>
                كل الحصص
              </option>
              {lessons
                ?.filter(
                  (lesson) =>
                    Number(lesson.term_taxonomy_id) === Number(currentCat)
                )
                .map((lesson) => (
                  <option value={lesson.ID}>{lesson.post_title}</option>
                ))}
            </select>
            <button type="submit" className="btn ea-btn rounded-ea my-2">
              عرض التقرير
            </button>
          </form>
          {!isPending && (
            <>
              {invoices && (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">رقم العملية</th>
                      <th scope="col">الحصة</th>
                      <th scope="col">الطالب</th>
                      <th scope="col">رقم الطالب</th>
                      <th scope="col">التاريخ</th>
                      <th scope="col">الإجمالي</th>
                      <th scope="col">تفاصيل</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices?.map((invoice) => (
                      <tr key={invoice.order_id}>
                        <td scope="row">{invoice.order_id}</td>
                        <td scope="row">
                          {
                            lessons.find((lesson) => {
                              return currentLesson === "all"
                                ? +lesson.ID === +invoice.product_id
                                : +lesson.ID === +currentLesson;
                            })?.post_title
                          }
                        </td>
                        <td scope="row">{invoice.user_name}</td>
                        <td scope="row">{invoice.user_phone}</td>
                        <td>{dateFormatter(invoice.date_created)}</td>
                        <td>{invoice.order_total} جنيه</td>
                        <td>
                          <Link
                            to={`/invoices/${invoice.order_id}`}
                            style={{ maxHeight: "30px", padding: "3px 8px" }}
                            className="pt-0 btn ea-btn rounded-ea"
                          >
                            تفاصيل
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <div className="d-flex">
                {invoices && currentPage * 10 < allInvoices.length && (
                  <button
                    onClick={() => setCurrentPage((prev) => prev + 1)}
                    className="m-1 btn ea-btn rounded-ea"
                  >
                    التالي
                  </button>
                )}
                {invoices && currentPage > 1 && (
                  <button
                    onClick={() => setCurrentPage((prev) => prev - 1)}
                    className="m-1 btn ea-btn rounded-ea"
                  >
                    السابق
                  </button>
                )}
              </div>
              {error && <h2>يجب تسجيل الدخول اولا</h2>}
            </>
          )}
        </div>
        {isPending && <Spinner />}
      </div>
    </Animation>
  );
};

export default TeacherSales;
