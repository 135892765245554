import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { NavLink as Link } from "react-router-dom";
import { endpoints } from "../../hooks/endpoints";
import { getToken } from "../../hooks/utils";
import { useAppSelector } from "../../store/hooks";
import Animation from "../Animation";
import Spinner from "../Spinner";

const Grades = () => {
  // get the results according to user's id from backend
  // filter the results according to "results.includes(currentTeacher)"
  // each result should have
  // ( id, cat(slug), cat(id), cat(title), score, quiz_name, quiz_id )
  // in the single result page we get ( questions (array of arrays), student_answers )
  // inside questions array of arrays, each array represent a single question and has
  // ( id, img, answers, weight, correct_answer, student_answer, hide_correct_answers )
  // displaying the quizzes results will be in a table similar to the invoices
  // displaying the single quiz result will be similar to the homework inside lesson

  const [gradesStart, setGradesStart] = useState(0);
  const { currentTeacher, user } = useAppSelector((state) => state.store);

  const fetchGrades = async () => {
    const res = await (
      await fetch(`${endpoints.quizGrades}?teacher=${currentTeacher}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        method: "GET",
      })
    ).json();
    return res;
  };

  const {
    data: grades,
    isPending,
    error,
  } = useQuery({
    queryKey: ["grades", user],
    queryFn: fetchGrades,
    staleTime: 5 * 60 * 1000,
  });

  if (isPending) {
    return (
      <Animation>
        <div className="container responsive-pages py-3">
          <h2 className="p-3 my-2 bg-ea-primary rounded-ea text-white">
            إمتحاناتي
          </h2>
          <div className="table-responsive bg-white responsive-padding-pages-containers rounded-ea shadow p-2">
            <Spinner />
          </div>
        </div>
      </Animation>
    );
  }

  if (!grades || grades?.length < 1 || grades?.status === 404) {
    return (
      <Animation>
        <div className="container responsive-pages py-3">
          <h2 className="p-3 my-2 bg-ea-primary rounded-ea text-white">
            إمتحاناتي
          </h2>
          <div className="table-responsive bg-white responsive-padding-pages-containers rounded-ea shadow p-2">
            <h2 className="text-center">لا يوجد إمتحانات</h2>
          </div>
        </div>
      </Animation>
    );
  }

  return (
    <Animation>
      <div className="container responsive-pages py-3">
        <h2 className="p-3 my-2 bg-ea-primary rounded-ea text-white">
          إمتحاناتي
        </h2>
        {/* {invoices && <h4 className="p-2">إجمالي المشتريات ({invoices.length})</h4>} */}
        {grades && (
          <Animation>
            <div className="table-responsive bg-white responsive-padding-pages-containers rounded-ea shadow">
              {grades && (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">الحصة</th>
                      <th scope="col">الدرجة</th>
                      <th scope="col">التاريخ</th>
                      <th scope="col">تفاصيل</th>
                    </tr>
                  </thead>
                  <tbody>
                    {grades &&
                      grades?.map(
                        (grade, index) =>
                          index >= gradesStart &&
                          index < gradesStart + 10 && (
                            <tr>
                              <th scope="row">{grade.lesson_name}</th>
                              <td>
                                {grade.raw_data &&
                                  `${JSON.parse(grade.raw_data).maxScore} / ${
                                    JSON.parse(grade.raw_data).score
                                  }`}
                              </td>
                              <td>{grade.date}</td>
                              <td>
                                {grade.raw_data && (
                                  <Link
                                    to={`/grades/${grade.id}`}
                                    style={{
                                      maxHeight: "30px",
                                      padding: "3px 8px",
                                    }}
                                    className="pt-0 btn ea-btn rounded-ea"
                                  >
                                    تفاصيل
                                  </Link>
                                )}
                              </td>
                            </tr>
                          )
                      )}
                  </tbody>
                </table>
              )}
              <div className="d-flex">
                {grades && gradesStart + 10 < grades.length && (
                  <button
                    onClick={() => setGradesStart((prev) => prev + 10)}
                    className="m-1 btn ea-btn rounded-ea"
                  >
                    التالي
                  </button>
                )}
                {grades && gradesStart > 0 && (
                  <button
                    onClick={() => setGradesStart((prev) => prev - 10)}
                    className="m-1 btn ea-btn rounded-ea"
                  >
                    السابق
                  </button>
                )}
              </div>
            </div>
          </Animation>
        )}
      </div>
    </Animation>
  );
};

export default Grades;
