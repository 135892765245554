import { Link, useLocation } from "react-router-dom";

const GradesBtn = () => {
  const { pathname } = useLocation();

  const active = "/grades" === pathname;

  return (
    <div className="d-flex flex-column gap-1 align-items-center">
      <Link to="/grades">
        <svg
          style={{
            transition: "all 0.2s ease-in-out",
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          fill="currentColor"
          className={`bi bi-clipboard-check fs-3 fw-bold p-2 rounded-ea m-0 ${
            active ? "text-white bg-ea-primary" : "text-ea-primary bg-white"
          }`}
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0"
          />
          <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
          <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
        </svg>
      </Link>
      <p
        className="text-center m-0 p-0 text-ea-primary"
        style={{ fontSize: "0.8rem" }}
      >
        إمتحاناتي
      </p>
    </div>
  );
};

export default GradesBtn;
