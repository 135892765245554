import { useEffect } from "react";
import AttendanceDash from "../AttendanceDash";
import AttendanceReports from "../AttendanceReports";
import CouponCreation from "../CouponCreation";
import CreateHomework from "../CreateHomework";
import CreateLesson from "../CreateLesson";
import CreateQuiz from "../CreateQuiz";
import EditLesson from "../EditLesson";
import EditQuiz from "../EditQuiz";
import ManageGrades from "../ManageGrades";
import ManageGroups from "../ManageGroups";
import ManageStudent from "../ManageStudent";
import NotificationsDash from "../NotificationsDash/NotificationsDash";
import QuestionBank from "../QuestionBank";
import QuizResults from "../QuizResults";
import TeacherSales from "../TeacherSales";

const dashboardItems = [
  {
    text: "نشر حصة جديدة",
    icon: "bi bi-send",
    component: <CreateLesson />,
    id: "createLesson",
  },
  {
    text: "إنشاء امتحان",
    icon: "bi bi-file-earmark-text",
    component: <CreateQuiz />,
    id: "createQuiz",
  },
  {
    text: "إنشاء واجب",
    icon: "bi bi-file-earmark-text",
    component: <CreateHomework />,
    id: "createHomework",
  },
  {
    text: "مبيعات الحصص",
    icon: "bi bi-currency-dollar",
    component: <TeacherSales />,
    id: "teacherSales",
  },
  {
    text: "نتائج الإمتحانات",
    icon: "bi bi-clipboard2-data",
    component: <QuizResults />,
    id: "quizResults",
  },
  {
    text: "إشعارات",
    icon: "bi bi-envelope",
    component: <NotificationsDash />,
    id: "notifications",
  },
  {
    text: "تقارير الحضور و الغياب",
    icon: "bi bi-clipboard2-data",
    component: <AttendanceReports />,
    id: "attendanceReports",
  },
  {
    text: "إنشاء أكواد",
    icon: "bi bi-ticket-perforated",
    component: <CouponCreation />,
    id: "couponCreation",
  },
  {
    text: "تعديل حصة",
    icon: "bi bi-gear",
    component: <EditLesson />,
    id: "editLesson",
  },
  {
    text: "تعديل امتحان/واجب",
    icon: "bi bi-gear",
    component: <EditQuiz />,
    id: "editQuiz",
  },
  {
    text: "إدارة الطلاب",
    icon: "bi bi-gear",
    component: <ManageStudent />,
    id: "manageStudent",
  },
  {
    text: "بنك الأسئلة",
    icon: "bi bi-gear",
    component: <QuestionBank />,
    id: "questionBank",
  },
  {
    text: "إدارة الأقسام",
    icon: "bi bi-gear",
    component: <ManageGrades />,
    id: "manageCats",
  },
  {
    text: "إدارة مجاميع الأوفلاين",
    icon: "bi bi-gear",
    component: <ManageGroups />,
    id: "manageGroups",
  },
  {
    text: "الحضور و الغياب",
    icon: "bi bi-gear",
    component: <AttendanceDash />,
    id: "attendance",
  },
];

const DashboardItems = ({
  setCurrentComponent,
  setCurrentButton,
  currentButton,
}) => {
  useEffect(() => {
    setCurrentButton(dashboardItems[0].id);
    setCurrentComponent(dashboardItems[0].component);
  }, []);

  const handleComponentChange = (comp, btn) => {
    setCurrentComponent(comp);
    setCurrentButton(btn);
  };

  return (
    <>
      {dashboardItems.map((item) => (
        <button
          style={{ textAlign: "right" }}
          onClick={() => handleComponentChange(item.component, item.id)}
          className={`text-white my-2 p-2 w-100 fw-bold my-1 ${
            currentButton === item.id
              ? "bg-ea-primary rounded-ea border-0"
              : "bg-transparent border-0"
          }`}
          type="button"
        >
          <i className={`${item.icon} me-2`}></i>
          {item.text}
        </button>
      ))}
    </>
  );
};

export default DashboardItems;
