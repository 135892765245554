import usePWA from "../../hooks/usePWA";

function Footer() {
  const { isAppInstalled } = usePWA();
  const waNumber = "201012376009";

  if (isAppInstalled) return <></>;

  return (
    <>
      <a
        href={`https://api.whatsapp.com/send?phone=${waNumber}`}
        className="wa-float pt-2"
        target="_blank"
        style={{ bottom: isAppInstalled ? "80px" : "40px" }}
      >
        <i className="bi bi-whatsapp m-auto"></i>
      </a>
      <div className="footer d-flex p-2">
        <p className="m-auto h6">
          Copyright © 2024, All Rights Reserved, Elite Academy
        </p>
      </div>
    </>
  );
}

export default Footer;
