import {
  fetchAndActivate,
  getValue,
  RemoteConfig,
} from "firebase/remote-config";
import { useEffect } from "react";

const useCheckForUpdates = ({
  remoteConfig,
  key,
}: {
  remoteConfig: RemoteConfig;
  key: string;
}) => {
  useEffect(() => {
    const interval = setInterval(async () => {
      fetchAndActivate(remoteConfig)
        .then(() => {
          const val = getValue(remoteConfig, key).asString();
          const localVal = localStorage.getItem(key);

          if (val !== localVal) {
            caches.keys().then((names) => {
              names.forEach(async (name) => {
                await caches.delete(name);
              });
              localStorage.setItem(key, val);
              location.reload();
            });
            console.log("remoteConfig: app update to latest version");
          }
          console.log("remoteConfig: app is at latest version");
        })
        .catch((err) => {
          console.log("remoteConfig: could not update", err);
        });
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  return null;
};

export default useCheckForUpdates;
