import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useGoogleLogin } from "@react-oauth/google";
import { signInWithCustomToken } from "firebase/auth";
import GoogleIcon from "../../assets/images/google-icon.svg";
import { auth } from "../../firebaseConfig";
import { endpoints } from "../../hooks/endpoints";
import { isEmailValid, isNameValid, isPhoneValid } from "../../hooks/utils";
import { useAppDispatch } from "../../store/hooks";
import { userData } from "../../store/slices/storeSlice";

interface RegistrationFormI {
  registerPhone: string;
  registerName: string;
  registerEmail: string;
  registerPassword: string;
  registerSchool: string;
  registerCity: string;
  registerFatherPhone: string;
  registerMotherPhone: string;
}

const RegistrationForm = () => {
  const username2 = useRef<HTMLInputElement>();
  const email = useRef<HTMLInputElement>();
  const school = useRef<HTMLInputElement>();
  const city = useRef<HTMLInputElement>();
  const firstName = useRef<HTMLInputElement>();
  const fatherPhone = useRef<HTMLInputElement>();
  const motherPhone = useRef<HTMLInputElement>();
  const password2 = useRef<HTMLInputElement>();
  const [responseMsg2, setResponseMsg2] = useState<any>();
  const [isLoginPending, setIsLoginPending] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [arabicAlert, setArabicAlert] = useState(false);
  const [isGoogleWindowOpen, setIsGoogleWindowOpen] = useState(false);
  const [isLoginWithFacebook, setIsLoginWithFacebook] = useState(false);

  const removeArabicChars = (e) => {
    let str = e.target.value;
    const newStr = str.replaceAll(/[\u0600-\u06FF]/g, "");
    e.target.value = newStr;
    setArabicAlert(str !== newStr);
  };

  useEffect(() => {
    if (!arabicAlert) return;
    setTimeout(() => {
      setArabicAlert(false);
    }, 5000);
  }, [arabicAlert]);

  const handleRegistrationSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const formDataObject = Object.fromEntries(
      formData
    ) as unknown as RegistrationFormI;

    console.log("formDataObject", formDataObject);

    formDataObject.registerPhone = formDataObject.registerPhone
      ?.trim()
      .replaceAll(" ", "");
    formDataObject.registerName = formDataObject.registerName?.trim();
    formDataObject.registerEmail = formDataObject.registerEmail
      ?.trim()
      .replaceAll(" ", "");
    formDataObject.registerPassword = formDataObject.registerPassword
      ?.trim()
      .replaceAll(" ", "");
    formDataObject.registerSchool = formDataObject.registerSchool?.trim();
    formDataObject.registerCity = formDataObject.registerCity?.trim();
    formDataObject.registerFatherPhone = formDataObject.registerFatherPhone
      ?.trim()
      .replaceAll(" ", "");
    formDataObject.registerMotherPhone = formDataObject.registerMotherPhone
      ?.trim()
      .replaceAll(" ", "");

    const checkName = isNameValid(formDataObject.registerName);
    if (!checkName.isValid) {
      (firstName.current as any).style = "border: 1px solid red";
      return setResponseMsg2(checkName.message);
    } else {
      (firstName.current as any).style = "";
    }

    const checkPhone = isPhoneValid(formDataObject.registerPhone);
    if (!checkPhone.isValid) {
      (username2.current as any).style = "border: 1px solid red";
      return setResponseMsg2(checkPhone.message);
    } else {
      (username2.current as any).style = "";
    }

    const checkFPhone = isPhoneValid(formDataObject.registerFatherPhone);
    if (!checkFPhone.isValid) {
      (fatherPhone.current as any).style = "border: 1px solid red";
      return setResponseMsg2(checkFPhone.message);
    } else {
      (fatherPhone.current as any).style = "";
    }

    const checkMPhone = isPhoneValid(formDataObject.registerMotherPhone);
    if (!checkMPhone.isValid) {
      (motherPhone.current as any).style = "border: 1px solid red";
      return setResponseMsg2(checkMPhone.message);
    } else {
      (motherPhone.current as any).style = "";
    }

    const checkEmail = isEmailValid(formDataObject.registerEmail);
    if (!checkEmail.isValid) {
      (email.current as any).style = "border: 1px solid red";
      return setResponseMsg2(checkEmail.message);
    } else {
      (email.current as any).style = "";
    }

    setIsLoginPending(true);
    const credentials = {
      username: formDataObject.registerPhone,
      password: formDataObject.registerPassword,
      email: formDataObject.registerEmail,
      father_phone: formDataObject.registerFatherPhone,
      mother_phone: formDataObject.registerMotherPhone,
      school: formDataObject.registerSchool,
      city: formDataObject.registerCity,
      first_name: formDataObject.registerName,
    };
    fetch(endpoints.createUser, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(credentials),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("result", result);
        if (result.status === 400) {
          setResponseMsg2(result.message);
        } else if (result.token) {
          signInWithCustomToken(auth, result.firebase_token)
            .then((userCredential) => {
              // Signed in
              setIsLoginPending(false);
              const user = userCredential.user;
              dispatch(userData(result));
              localStorage.setItem(
                `${window.location.hostname}_user`,
                JSON.stringify(result)
              );
              console.log("User Logged In");
              navigate("/complete-info");
              // ...
            })
            .catch((error) => {
              setIsLoginPending(false);
              const errorCode = error.code;
              const errorMessage = error.message;
              throw new Error(errorMessage);
            });
        } else if (!result.is_valid) {
          setIsLoginPending(false);
          (username2.current as any).style = "";
          (fatherPhone.current as any).style = "";
          (motherPhone.current as any).style = "";
          (firstName.current as any).style = "";
          (email.current as any).style = "";

          if (result.field === "phone") {
            (username2.current as any).style = "border: 1px solid red";
            setResponseMsg2(result.message);
          } else if (result.field === "father_phone") {
            (fatherPhone.current as any).style = "border: 1px solid red";
            setResponseMsg2(result.message);
          } else if (result.field === "mother_phone") {
            (motherPhone.current as any).style = "border: 1px solid red";
            setResponseMsg2(result.message);
          } else if (result.field === "name") {
            (firstName.current as any).style = "border: 1px solid red";
            setResponseMsg2(result.message);
          } else if (result.field === "email") {
            (email.current as any).style = "border: 1px solid red";
            setResponseMsg2(result.message);
          }
        }
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoginPending(false);
        setResponseMsg2("حدث خطأ");
      });
  };

  const validateGoogleTokenOnServer = (code, currentPageUrl) => {
    setIsLoginPending(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      code,
      url: currentPageUrl,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    } as RequestInit;
    fetch(endpoints.googleSocialLogin, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        console.log(result, "credentialResponse");
        if (result.token) {
          signInWithCustomToken(auth, result.firebase_token)
            .then((userCredential) => {
              // Signed in
              setIsLoginPending(false);
              const user = userCredential.user;
              console.log({ user });
              dispatch(userData(result));
              localStorage.setItem(
                `${window.location.hostname}_user`,
                JSON.stringify(result)
              );
              setIsGoogleWindowOpen(false);
              navigate("/complete-info");
              // ...
            })
            .catch((error) => {
              setIsLoginPending(false);
              const errorCode = error.code;
              const errorMessage = error.message;
              throw new Error(errorMessage);
              // ...
            });
        } else {
          setIsLoginPending(false);
          const erMsg = document.getElementById("ea-err-msg2");
          erMsg.classList.add(
            "alert",
            "alert-danger",
            "mt-3",
            "align-self-center",
            "text-center",
            "rounded-ea"
          );
          erMsg.classList.remove("invisible");
          setResponseMsg2("الإيميل مش صح");
          setIsGoogleWindowOpen(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setResponseMsg2("حدث خطأ");
        setIsGoogleWindowOpen(false);
      });
  };

  const loginWithGoogle = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (GoogleResponse) => {
      console.log("Login Success:", GoogleResponse);
      validateGoogleTokenOnServer(GoogleResponse.code, window.location.origin);
    },
    onError: (error) => {
      console.log("Login Failed:", error);
      setResponseMsg2("حدث خطأ");
      setIsGoogleWindowOpen(false);
    },
    onNonOAuthError: () => {
      setIsGoogleWindowOpen(false);
    },
  });

  return (
    <form
      onSubmit={handleRegistrationSubmit}
      className="m-3 bg-transparent registration-form"
    >
      <div className="login-register-wrapper-grid">
        <div className="form-floating mb-2 text-ea-primary">
          <input
            type="text"
            id="registerName"
            name="registerName"
            // minLength={15}
            className="form-control
                rounded-0
                text-ea-primary
                bg-transparent
                border-bottom-3
                border-top-0
                border-start-0
                border-end-0"
            placeholder=""
            ref={firstName}
            // onChange={(e) => removeArabicChars(e)}
            required
          />
          <label htmlFor="registerName">الإسم رباعي</label>
        </div>
        <div className="form-floating mb-2 text-ea-primary">
          <input
            type="text"
            id="registerPhone"
            name="registerPhone"
            // maxLength={11}
            // minLength={11}
            className="form-control
                rounded-0
                text-ea-primary
                bg-transparent
                border-bottom-3
                border-top-0
                border-start-0
                border-end-0"
            placeholder=""
            ref={username2}
            onChange={(e) => removeArabicChars(e)}
            required
          />
          <label htmlFor="registerPhone">التلفون (به واتساب)</label>
        </div>
        <div className="form-floating mb-2 text-ea-primary">
          <input
            type="text"
            id="registerFatherPhone"
            name="registerFatherPhone"
            // maxLength={11}
            // minLength={11}
            className="form-control
                rounded-0
                text-ea-primary
                bg-transparent
                border-bottom-3
                border-top-0
                border-start-0
                border-end-0"
            placeholder=""
            ref={fatherPhone}
            onChange={(e) => removeArabicChars(e)}
            required
          />
          <label htmlFor="registerFatherPhone">تليفون الأب</label>
        </div>
        <div className="form-floating mb-2 text-ea-primary">
          <input
            type="text"
            id="registerMotherPhone"
            name="registerMotherPhone"
            // maxLength={11}
            // minLength={11}
            className="form-control
                rounded-0
                text-ea-primary
                bg-transparent
                border-bottom-3
                border-top-0
                border-start-0
                border-end-0"
            placeholder=""
            ref={motherPhone}
            onChange={(e) => removeArabicChars(e)}
            required
          />
          <label htmlFor="registerMotherPhone">تليفون الأم</label>
        </div>
        <div className="form-floating mb-2 text-ea-primary">
          <input
            type="text"
            id="registerCity"
            name="registerCity"
            className="form-control
                rounded-0
                text-ea-primary
                bg-transparent
                border-bottom-3
                border-top-0
                border-start-0
                border-end-0"
            placeholder=""
            ref={city}
            // onChange={(e) => removeArabicChars(e)}
            required
          />
          <label htmlFor="registerCity">المحافظة</label>
        </div>
        <div className="form-floating mb-2 text-ea-primary">
          <input
            type="text"
            id="registerSchool"
            name="registerSchool"
            className="form-control
                rounded-0
                text-ea-primary
                bg-transparent
                border-bottom-3
                border-top-0
                border-start-0
                border-end-0"
            placeholder=""
            ref={school}
            // onChange={(e) => removeArabicChars(e)}
            required
          />
          <label htmlFor="registerSchool">المدرسة</label>
        </div>
        <div className="form-floating mb-2 text-ea-primary">
          <input
            type="text"
            id="registerEmail"
            name="registerEmail"
            className="form-control
                rounded-0
                text-lighea-primaryt
                bg-transparent
                border-bottom-3
                border-top-0
                border-start-0
                border-end-0"
            placeholder=""
            ref={email}
            onChange={(e) => removeArabicChars(e)}
            required
          />
          <label htmlFor="registerEmail">الإيميل</label>
        </div>

        <div className="form-floating mb-2 text-ea-primary">
          <input
            type="password"
            id="registerPassword"
            name="registerPassword"
            className="form-control
                rounded-0
                text-ea-primary
                bg-transparent
                border-bottom-3
                border-top-0
                border-start-0
                border-end-0"
            placeholder="***"
            ref={password2}
            onChange={(e) => removeArabicChars(e)}
            required
          />
          <label htmlFor="registerPassword">كلمة السر</label>
        </div>
      </div>
      <div className="social-login-container">
        <button
          type="button"
          className="btn google-login rounded-ea text-light social-login-btns"
          disabled={isGoogleWindowOpen}
          style={{
            pointerEvents: isGoogleWindowOpen ? "none" : "auto",
            opacity: isGoogleWindowOpen ? "0.5" : "1",
          }}
          onClick={() => {
            loginWithGoogle();
            setIsGoogleWindowOpen(true);
          }}
        >
          التسجيل بجوجل{" "}
          <img
            style={{ margin: "0 5px", justifySelf: "center" }}
            src={GoogleIcon}
            alt="google"
          />
        </button>
        {/* <a
          style={{
            pointerEvents: isLoginWithFacebook ? "none" : "auto",
            opacity: isLoginWithFacebook ? "0.5" : "1",
          }}
          className="btn facebook-login rounded-ea text-light social-login-btns"
          href={`https://www.facebook.com/v20.0/dialog/oauth?client_id=3331531460486266&redirect_uri=${window.location.origin}${window.location.pathname}`}
        >
          التسجيل بفيسبوك{" "}
          <img
            style={{ margin: "0 5px", justifySelf: "center" }}
            src={FacebookIcon}
            alt="facebook"
          />
        </a> */}
      </div>
      {!isLoginPending && (
        <button
          type="submit"
          className="btn rounded-ea ea-btn text-light w-100"
        >
          إنشاء
        </button>
      )}
      {isLoginPending && (
        <button
          disabled
          className="btn rounded-ea ea-btn text-light w-100"
          type="submit"
        >
          <span
            className="spinner-border spinner-border-sm ms-2 me-2"
            role="status"
            aria-hidden="true"
          ></span>
          جاري التسجيل...
        </button>
      )}
      {responseMsg2 && (
        <div
          id="ea-err-msg"
          className="border-warning alert alert-warning mt-3 align-self-center text-dark rounded-ea"
          role="alert"
        >
          {responseMsg2}
        </div>
      )}
      {arabicAlert && (
        <div
          id="ea-err-msg"
          className="border-danger rounded-ea alert alert-danger mt-3 align-self-center text-dark"
          role="alert"
        >
          لا يمكن إدخال الحروف العربية في هذة الخانة
        </div>
      )}
    </form>
  );
};

export default RegistrationForm;
