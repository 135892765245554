import { createSlice } from "@reduxjs/toolkit";

export const userRoles = {
  TEACHER: "teacher",
  ADMIN: "admin",
  STUDENT: "student",
} as const;

export interface User {
  uid: number;
  user_display_name: string;
  user_nicename: string;
  user_email: string;
  role: (typeof userRoles)[keyof typeof userRoles];
  data_completed: boolean;
  token: string;
  firebase_token: string;
}

export interface Portal {
  ID: number;
  bgImg: string;
  category: string;
  logoUrl: string;
  favicon: string;
  manifest: string;
  name: string;
  cardImg: string;
  allowed_payment_methods: string[];
  portal: string;
  cats: Category[];
  offline_groups: string[];
  data: Lesson[];
  current_app_version?: number;
  remove_service_worker?: boolean;
}

export interface Category {
  term_id: number;
  name: string;
  slug: string;
  term_group: number;
  term_taxonomy_id: number;
  taxonomy: string;
  description?: string;
  parent: number;
  count: number;
  filter: string;
  order: string;
}

export interface Lesson {
  post_title: string;
  post_name: string;
  _regular_price: string;
  term_taxonomy_id: number;
  term_name: string;
  slug: string;
  post_date: string;
  ID: string;
}

export interface UserData {
  ID: number;
  user_login: string;
  user_email: string;
  display_name: string;
  purchases: any[];
  quizzes: any[] | null;
  homeworks: any[] | null;
  exams: any[] | null;
  disabled_sites: any[] | null;
  school: string;
  city: string;
  father_phone: string;
  mother_phone: string;
  group: string;
  profile_pic: string;
  domain: string;
  teacher_name?: string;
  teacher_subject?: string;
}

export interface Notification {
  ID: number;
  title: string;
  body: string;
  icon?: string;
}

const initialState: {
  user: User;
  userProfileData: UserData;
  isTeacher: boolean;
  currentTeacher: string;
  teachers: any;
  wallet: any;
  purchases: number[] | string[] | null;
  portal: Portal;
  portalRefetch: number;
  userRefetch: number;
  currentQuizProgress: { [key: string]: string };
  currentHwProgress: { [key: string]: string };
  notifications: Notification[];
} = {
  user: null,
  userProfileData: null,
  isTeacher: false,
  currentTeacher: null,
  teachers: null,
  wallet: "",
  purchases: [],
  portal: null,
  portalRefetch: 0,
  userRefetch: 0,
  currentQuizProgress: {},
  currentHwProgress: {},
  notifications: [],
};

export const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    userData: (state, action) => {
      state.user = action.payload;
    },
    setUserProfileData: (state, action) => {
      state.userProfileData = action.payload;
    },
    updateUserPurchases: (state, action) => {
      state.purchases = action.payload;
    },
    currentTeacherData: (state, action) => {
      state.currentTeacher = action.payload;
    },
    userIsTeacher: (state, action) => {
      state.isTeacher = action.payload;
    },
    teachersData: (state, action) => {
      state.teachers = action.payload;
    },
    walletData: (state, action) => {
      state.wallet = action.payload;
    },
    setPortal: (state, action) => {
      state.portal = action.payload;
    },
    setCurrentQuizProgress: (state, action) => {
      state.currentQuizProgress = {
        ...state.currentQuizProgress,
        ...action.payload,
      };
    },
    setCurrentHwProgress: (state, action) => {
      state.currentHwProgress = {
        ...state.currentHwProgress,
        ...action.payload,
      };
    },
    addNotification: (state, action: { payload: Notification }) => {
      state.notifications = [...state.notifications, action.payload];
    },
    removeNotification: (state, action: { payload: number }) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.ID !== action.payload
      );
    },
    invokePortalRefetch: (state) => {
      state.portalRefetch += 1;
    },
    invokeUserRefetch: (state) => {
      state.userRefetch += 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  userData,
  teachersData,
  walletData,
  userIsTeacher,
  currentTeacherData,
  updateUserPurchases,
  setPortal,
  setUserProfileData,
  invokePortalRefetch,
  invokeUserRefetch,
  setCurrentQuizProgress,
  setCurrentHwProgress,
  addNotification,
  removeNotification,
} = storeSlice.actions;

export default storeSlice.reducer;
