import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { endpoints } from "../../hooks/endpoints";
import {
  getFileUrl,
  getToken,
  getUID,
  isEmailValid,
  isNameValid,
  isPhoneValid,
} from "../../hooks/utils";
import Spinner from "../Spinner";

import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../firebaseConfig";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { invokeUserRefetch } from "../../store/slices/storeSlice";
// import "../../styles/resetFileInput.css";
// import "../../styles/profilePic.css";

interface UserDataI {
  billing_first_name: string;
  billing_phone: string;
  billing_email: string;
  father_phone: string;
  mother_phone: string;
  city: string;
  school: string;
  group: string;
}

interface CompleteInfoFormI {
  name: string;
  email: string;
  phone: string;
  fphone: string;
  mphone: string;
  city: string;
  school: string;
  group: string;
}

const CompleteInfo = () => {
  const defaultProfilePic =
    "https://eliteacademymedia.s3.amazonaws.com/eliteacademymedia/blank-profile-picture-973460_960_720.webp";
  const [pending, setPending] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  const [userData, setUserData] = useState<UserDataI>();
  const [profilePicState, setProfilePicState] = useState("");
  const [fileProgress, setFileProgress] = useState(0);
  const [imageLoading, setImageLoading] = useState(false);
  const [uploadedProfilePic, setUploadedProfilePic] =
    useState(defaultProfilePic);
  const [arabicAlert, setArabicAlert] = useState(false);
  const name = useRef();
  const email = useRef();
  const phone = useRef();
  const fphone = useRef();
  const mphone = useRef();
  const city = useRef();
  const school = useRef();
  const group = useRef();
  const profilePic = useRef();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { portal } = useAppSelector((state) => state.store);

  const removeArabicCharsFromPhoneNumber = (e) => {
    let str = e.target.value;
    const newStr = str.replaceAll(/[\u0600-\u06FF]/g, "");
    e.target.value = newStr;
    setArabicAlert(str !== newStr);
    setUserData((prev) => ({
      ...prev,
      billing_phone: newStr,
    }));
    //create an alert div here
    // if (newStr !== str) alert("لا يمكن إدخال الحروف العربية في هذة الخانة");
  };

  useEffect(() => {
    if (!arabicAlert) return;
    setErrMsg(false);
    setTimeout(() => {
      setArabicAlert(false);
    }, 5000);
  }, [arabicAlert]);

  useEffect(() => {
    setIsPending(true);
    fetch(endpoints.accountPage + `?group=${window.location.hostname}_group`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("cewfsafq", data);
        setIsPending(false);
        setUserData(data);
        if (profilePic.current && data.profile_pic) {
          setUploadedProfilePic(data.profile_pic);
        }
      })
      .catch((err) => {
        console.log({ err });
        setIsPending(false);
        setError("حدث خطأ");
      });
  }, []);

  const handleSubmitInfo = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const formDataObject = Object.fromEntries(
      formData
    ) as unknown as CompleteInfoFormI;

    formDataObject.phone = formDataObject.phone?.trim().replaceAll(" ", "");
    formDataObject.name = formDataObject.name?.trim();
    formDataObject.email = formDataObject.email?.trim().replaceAll(" ", "");
    formDataObject.school = formDataObject.school?.trim();
    formDataObject.city = formDataObject.city?.trim();
    formDataObject.fphone = formDataObject.fphone?.trim().replaceAll(" ", "");
    formDataObject.mphone = formDataObject.mphone?.trim().replaceAll(" ", "");

    console.log("formDataObject", formDataObject, userData);

    // Check name
    const checkName = isNameValid(
      formDataObject.name ?? userData.billing_first_name
    );
    if (name.current) {
      if (!checkName.isValid) {
        if (name.current) (name.current as any).style = "border: 1px solid red";
        return setErrMsg(checkName.message);
      } else {
        if (name.current) (name.current as any).style = "";
      }
    }

    // Check student phone
    const checkPhone = isPhoneValid(
      formDataObject.phone ?? userData.billing_phone
    );
    if (phone.current) {
      if (!checkPhone.isValid) {
        if (phone.current)
          (phone.current as any).style = "border: 1px solid red";
        console.log((phone.current as any).value);
        return setErrMsg(checkPhone.message);
      } else {
        (phone.current as any).style = "";
      }
    }

    // Check father phone
    const checkFPhone = isPhoneValid(
      formDataObject.fphone ?? userData.father_phone
    );
    if (fphone.current) {
      if (!checkFPhone.isValid) {
        (fphone.current as any).style = "border: 1px solid red";
        return setErrMsg(checkFPhone.message);
      } else {
        (fphone.current as any).style = "";
      }
    }

    // Check mother phone
    const checkMPhone = isPhoneValid(
      formDataObject.mphone ?? userData.mother_phone
    );
    if (mphone.current) {
      if (!checkMPhone.isValid) {
        (mphone.current as any).style = "border: 1px solid red";
        return setErrMsg(checkMPhone.message);
      } else {
        (mphone.current as any).style = "";
      }
    }

    // Check email
    const checkEmail = isEmailValid(
      formDataObject.email ?? userData.billing_email
    );
    if (email.current) {
      if (!checkEmail.isValid) {
        (email.current as any).style = "border: 1px solid red";
        return setErrMsg(checkEmail.message);
      } else {
        (email.current as any).style = "";
      }
    }

    // Check image
    // const image = document.getElementById("profilePicDiv");
    // if (image) {
    //   if (uploadedProfilePic === defaultProfilePic) {
    //     (image as any).style = "border: 1px solid red";
    //     return setErrMsg("الرجاء تحميل صورة شخصية");
    //   } else {
    //     (image as any).style = "";
    //   }
    // }

    setErrMsg(null);
    setPending(true);
    fetch(
      endpoints.updateMissingInfo2 + `?group=${window.location.hostname}_group`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        method: "POST",
        body: JSON.stringify({
          name: userData.billing_first_name,
          phone: userData.billing_phone,
          email: userData.billing_email,
          fphone: userData.father_phone,
          mphone: userData.mother_phone,
          city: userData.city,
          school: userData.school,
          group: userData.group,
          profile_pic: uploadedProfilePic ?? "",
          portal: window.location.hostname,
        }),
      }
    )
      .then((response) => response.json())
      .then(async (data) => {
        setPending(false);
        if (data?.is_valid === false) {
          console.log("debug is inside if");
          if (phone.current) (phone.current as any).style = "";
          if (fphone.current) (fphone.current as any).style = "";
          if (mphone.current) (mphone.current as any).style = "";
          if (name.current) (name.current as any).style = "";

          if (data.field === "phone") {
            if (phone.current)
              (phone.current as any).style = "border: 1px solid red";
            setErrMsg(data.message);
          } else if (data.field === "father_phone") {
            if (fphone.current)
              (fphone.current as any).style = "border: 1px solid red";
            setErrMsg(data.message);
          } else if (data.field === "mother_phone") {
            if (mphone.current)
              (mphone.current as any).style = "border: 1px solid red";
            setErrMsg(data.message);
          } else if (data.field === "name") {
            if (name.current)
              (name.current as any).style = "border: 1px solid red";
            setErrMsg(data.message);
          }
        } else if (data) {
          const localStorageData = await JSON.parse(
            localStorage.getItem(`${window.location.hostname}_user`)
          );
          localStorageData.data_completed = "1";
          localStorage.setItem(
            `${window.location.hostname}_user`,
            JSON.stringify(localStorageData)
          );
          dispatch(invokeUserRefetch());
          navigate("/school-grades");
        }
      })
      .catch((err) => {
        setPending(false);
      });
  };

  const handleUploadProfilePicFb = async (e) => {
    if (!e.target.files[0]) {
      alert("Please select a file.");
      return;
    }

    setImageLoading(true);

    const chunks = e.target.value.split("\\");
    const file = chunks[chunks.length - 1];
    setProfilePicState(file);

    try {
      const imageRef = ref(storage, `profile_pics-${getUID()}`);
      await uploadBytes(imageRef, e.target.files[0]);
      const url = await getDownloadURL(imageRef);
      setUploadedProfilePic(getFileUrl(url));
    } catch (error) {
      alert("حدث خطأ");
    }
  };

  const handleUploadProfilePic = async (e) => {
    if (!e.target.files[0]) {
      alert("Please select a file.");
      return;
    }

    setImageLoading(true);

    const chunks = e.target.value.split("\\");
    const file = chunks[chunks.length - 1];
    setProfilePicState(file);

    try {
      const formData = new FormData();
      formData.append("file", e.target.files[0], e.target.files[0].name);

      const xhr = new XMLHttpRequest();

      // Track the progress of the upload
      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          console.log(
            "uploadProgress",
            `Upload Progress: ${percentComplete.toFixed(2)}%`
          );
          if (percentComplete.toFixed(2) === "100.00") {
            setFileProgress(null);
          } else {
            setFileProgress(+percentComplete.toFixed(2));
          }
          // You can update a progress bar or display the percentage as needed
        }
      });

      xhr.open("POST", endpoints.aws, true);
      // xhr.open("POST", endpoints.imageUpload, true);
      // xhr.open("POST", endpoints.drive, true);

      // Add the Authorization header with the token
      const token = JSON.parse(
        localStorage.getItem(`${window.location.hostname}_user`)
      ).token;
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            try {
              const data = JSON.parse(xhr.responseText);

              if (data.success) {
                // File uploaded successfully
                // data.url
                setUploadedProfilePic(data.url);
                console.log({ "data.url": data });
                setFileProgress(null);
              } else {
                // File upload failed
                alert("File upload failed. " + data.message);
                setFileProgress(null);
              }
            } catch (error) {
              alert("حدث خطأ");
              setFileProgress(null);
            }
          } else {
            // Server error
            alert("حدث خطأ");
            setFileProgress(null);
          }
        }
      };
      console.log("casfagegwefw", formData);
      xhr.send(formData);
    } catch (error) {
      // Network error
      alert("An error occurred while uploading the file.");
    }
  };

  return (
    <div className="container responsive-pages py-3">
      <h2 className="p-3 my-2 bg-ea-primary rounded-ea text-white">
        إستكمال البيانات
      </h2>
      <div className="table-responsive bg-white responsive-padding-pages-containers rounded-ea shadow">
        {userData && (
          <form className="m-3 row" onSubmit={handleSubmitInfo}>
            <div
              className={`text-center m-2 mb-3 ${imageLoading && "loading"}`}
            >
              <img
                onLoad={() => setImageLoading(false)}
                ref={profilePic}
                style={{
                  aspectRatio: 1,
                  width: "12rem",
                  border: "3px solid var(--primary-color)",
                }}
                src={decodeURIComponent(uploadedProfilePic)}
                className="rounded rounded-circle"
                alt="صورة شخصية"
              />
            </div>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                الإسم رباعي
              </span>
              <input
                ref={name}
                value={userData.billing_first_name ?? ""}
                onChange={(e) => {
                  setUserData((prev) => ({
                    ...prev,
                    billing_first_name: e.target.value,
                  }));
                }}
                type="text"
                name="name"
                className="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
                required
              />
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  تليفون
                </span>
                <input
                  ref={phone}
                  value={userData.billing_phone ?? ""}
                  onChange={(e) => removeArabicCharsFromPhoneNumber(e)}
                  type="text"
                  name="phone"
                  className="form-control"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  disabled={isPhoneValid(userData.billing_phone ?? "")?.isValid}
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  إيميل
                </span>
                <input
                  ref={email}
                  value={userData.billing_email ?? ""}
                  onChange={(e) => {
                    setUserData((prev) => ({
                      ...prev,
                      billing_email: e.target.value,
                    }));
                  }}
                  type="text"
                  name="email"
                  className="form-control"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  disabled={!!userData.billing_email}
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  تليفون الأب
                </span>
                <input
                  ref={fphone}
                  value={userData.father_phone ?? ""}
                  onChange={(e) => {
                    setUserData((prev) => ({
                      ...prev,
                      father_phone: e.target.value,
                    }));
                  }}
                  type="text"
                  name="fPhone"
                  className="form-control"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  تليفون الأم
                </span>
                <input
                  ref={mphone}
                  value={userData.mother_phone ?? ""}
                  onChange={(e) => {
                    setUserData((prev) => ({
                      ...prev,
                      mother_phone: e.target.value,
                    }));
                  }}
                  type="text"
                  name="mPhone"
                  className="form-control"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  المحافظة
                </span>
                <input
                  ref={city}
                  value={userData.city ?? ""}
                  onChange={(e) => {
                    setUserData((prev) => ({
                      ...prev,
                      city: e.target.value,
                    }));
                  }}
                  type="text"
                  name="city"
                  className="form-control"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  المدرسة
                </span>
                <input
                  ref={school}
                  value={userData.school ?? ""}
                  onChange={(e) => {
                    setUserData((prev) => ({
                      ...prev,
                      school: e.target.value,
                    }));
                  }}
                  type="text"
                  name="school"
                  className="form-control"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  المجموعة
                </span>
                <select
                  className="form-select form-select-md"
                  aria-label=".form-select-lg example"
                  name="group"
                  ref={group}
                  value={userData.group ?? ""}
                  onChange={(e) => {
                    setUserData((prev) => ({
                      ...prev,
                      group: e.target.value,
                    }));
                  }}
                  required
                >
                  <option value="" selected>
                    إختر المجموعة
                  </option>
                  {(portal?.offline_groups?.length > 0
                    ? portal?.offline_groups
                    : ["أونلاين"]
                  ).map((group) => (
                    <option value={group}>{group}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="input-group mb-3">
                <label
                  id="profilePic"
                  className="input-group-text"
                  htmlFor="inputGroupFile01"
                >
                  صورة شخصية (إختياري)
                </label>
                <input
                  onChange={(e) => handleUploadProfilePicFb(e)}
                  type="file"
                  name="profilePic"
                  className="form-control border d-none"
                  id="inputGroupFile01"
                  required={false}
                />
                <div
                  id="profilePicDiv"
                  className="form-control"
                  onClick={() => {
                    const profilePicInput =
                      document.getElementById("profilePic");
                    profilePicInput.click();
                  }}
                >
                  {fileProgress === null || fileProgress === 0 ? (
                    profilePicState
                  ) : (
                    <div
                      style={{
                        width: fileProgress + "%",
                        height: "100%",
                        backgroundColor: "green",
                      }}
                    ></div>
                  )}
                </div>
              </div>
            </div>
            <input
              type="submit"
              className={`ea-btn btn rounded-ea ${pending ? "loading" : ""}`}
              value="إرسال البيانات"
            />
          </form>
        )}
        {errMsg && (
          <div className="d-flex p-0 m-0 justify-content-center">
            <p className="alert alert-danger rounded-ea m-3 text-center">
              {errMsg}
            </p>
          </div>
        )}
        {arabicAlert && (
          <div
            id="ea-err-msg"
            className="alert alert-danger rounded-ea m-3 text-center"
            role="alert"
          >
            لا يمكن إدخال الحروف العربية في هذة الخانة
          </div>
        )}
        {error && <h2>يجب تسجيل الدخول اولا</h2>}
      </div>
      {isPending && <Spinner />}
    </div>
  );
};

export default CompleteInfo;
