import { useNavigate } from "react-router";
import usePWA from "../../hooks/usePWA";
import "../../styles/install-app-page.css";
import ButtonsWrapper from "./ButtonsWrapper";
import MockupWrapper from "./MockupWrapper";

const InstallAppPage = () => {
  const { isAppInstalled } = usePWA();
  const navigate = useNavigate();

  if (isAppInstalled) navigate("/home");
  
  return (
    <div className="install-app-page">
      <h1 className="cta">
        حمل التطبيق و اوصل للحصص بسهولة و احصل على الإشعارات
      </h1>
      <ButtonsWrapper />
      <MockupWrapper />
    </div>
  );
};

export default InstallAppPage;
