import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { endpoints } from "../../hooks/endpoints";
import { getToken } from "../../hooks/utils";
import Animation from "../Animation";
import NotFound from "../NotFound";
import Spinner from "../Spinner";

const Invoice = () => {
  const { id } = useParams();

  const fetchInvoice = async () => {
    const res = await (
      await fetch(`${endpoints.invoice}?invoice=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        method: "GET",
      })
    ).json();
    return res;
  };

  const {
    data: invoice,
    isPending,
    error,
  } = useQuery({
    queryKey: ["invoice", id],
    queryFn: fetchInvoice,
    staleTime: 5 * 60 * 1000,
  });

  return (
    <Animation>
      <div className="container responsive-pages py-3">
        <h2 className="p-3 my-2 bg-ea-primary rounded-ea text-white">
          عملية شراء رقم ({id})
        </h2>
        <div className="table-responsive bg-white responsive-padding-pages-containers rounded-ea shadow">
          {invoice && (
            <>
              <div className="alert alert-primary mt-3" role="alert">
                التاريخ : ( {invoice.date} ) - الحالة : ({" "}
                {invoice.status === "completed" ? (
                  <p className="d-inline text-success fw-bold">تم الدفع</p>
                ) : invoice.status === "pending" ? (
                  <p className="d-inline text-danger fw-bold">منتظر الدفع</p>
                ) : (
                  invoice.status
                )}{" "}
                )
              </div>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">الحصة</th>
                    <th scope="col">القيمة</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">{invoice.lesson}</th>
                    <td>{invoice.subtotal} جنيه</td>
                  </tr>
                  <tr>
                    <th scope="row">شراء بالكود</th>
                    <td>
                      {invoice.code ? (
                        invoice.code
                      ) : (
                        <i className="bi bi-dash-lg"></i>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          {error && <NotFound />}
        </div>
        {invoice && invoice === "Not the customer" && <NotFound />}
        {isPending && <Spinner />}
      </div>
    </Animation>
  );
};

export default Invoice;
