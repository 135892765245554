import { useEffect, useRef, useState } from "react";

const usePWA = () => {
  const deferredPromptRef = useRef(null);
  const [isAppInstalled, setAppInstalled] = useState(false);
  const fullscreenQuery = window.matchMedia("(display-mode: fullscreen)");
  const standaloneQuery = window.matchMedia("(display-mode: standalone)");
  const listenForPwa = (e) => {
    e.preventDefault();
    console.log("beforeinstallprompt-elite", e);
    deferredPromptRef.current = e;
  };
  const installApp = async (togglePopup?: (x: boolean) => void) => {
    if (deferredPromptRef.current) {
      deferredPromptRef.current.prompt();
      deferredPromptRef.current.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        deferredPromptRef.current = null;
      });
    } else {
      console.log("No install prompt available");
      togglePopup?.(true);
    }
  };

  function getDisplayMode() {
    if (fullscreenQuery.matches) {
      return "fullscreen";
    } else if (standaloneQuery.matches) {
      return "standalone";
    } else if (window.matchMedia("(display-mode: minimal-ui)").matches) {
      return "minimal-ui";
    } else if (window.matchMedia("(display-mode: browser)").matches) {
      return "browser";
    } else if (
      window.matchMedia("(display-mode: window-controls-overlay)").matches
    ) {
      return "window-controls-overlay";
    } else {
      return "unknown";
    }
  }

  function checkIsPWA() {
    setAppInstalled(
      getDisplayMode() === "standalone" || getDisplayMode() === "fullscreen"
    );
  }

  useEffect(() => {
    checkIsPWA();
  }, []);

  useEffect(() => {
    fullscreenQuery.addEventListener("change", checkIsPWA);
    standaloneQuery.addEventListener("change", checkIsPWA);
    if (!isAppInstalled) {
      window.addEventListener("beforeinstallprompt", listenForPwa);
    }

    return () => {
      fullscreenQuery.removeEventListener("change", checkIsPWA);
      standaloneQuery.removeEventListener("change", checkIsPWA);
      if (!isAppInstalled) {
        window.removeEventListener("beforeinstallprompt", listenForPwa);
      }
    };
  }, []);

  return { installApp, isAppInstalled };
};

export default usePWA;
