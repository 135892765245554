import { Link, useLocation } from "react-router-dom";

const InvoicesBtn = () => {
  const { pathname } = useLocation();

  const active = "/invoices" === pathname;

  return (
    <div className="d-flex flex-column gap-1 align-items-center">
      <Link to="/invoices">
        <svg
          style={{
            transition: "all 0.2s ease-in-out",
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          fill="currentColor"
          className={`bi bi-cart fs-3 fw-bold p-2 rounded-ea m-0 ${
            active ? "text-white bg-ea-primary" : "text-ea-primary bg-white"
          }`}
          viewBox="0 0 16 16"
        >
          <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
        </svg>
      </Link>
      <p
        className="text-center m-0 p-0 text-ea-primary"
        style={{ fontSize: "0.8rem" }}
      >
        مشترياتي
      </p>
    </div>
  );
};

export default InvoicesBtn;
