import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardItems from "./DashboardComponents/DashboardItems";

import { isTeacher } from "../../hooks/utils";
import { useAppSelector } from "../../store/hooks";
import Animation from "../Animation";
import Spinner from "../Spinner";

const Dashboard = () => {
  const [currentComponent, setCurrentComponent] = useState();
  const [currentButton, setCurrentButton] = useState();

  const { user } = useAppSelector((state) => state.store);

  const navigate = useNavigate();

  if (!user)
    return (
      <div className="d-flex justify-content-center m-3">
        <Spinner />
      </div>
    );

  if (!isTeacher()) navigate("/school-grades");

  return (
    <Animation>
      <div className="row">
        <div
          className="col-4 col-md-2"
          style={{
            minHeight: "90vh",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            backgroundColor: "#333",
            padding: "0px 5px 0px 0px",
          }}
        >
          <div
            className="accordion m-0 text-white bg-transparent w-100 ps-0 pe-2 border-0"
            id="accordionExample"
          >
            <div className="accordion-item m-0 bg-transparent w-100 px-0 border-0">
              <h2
                className="accordion-header bg-transparent w-100 px-0"
                id="headingOne"
              >
                <button
                  className="accordion-button bg-transparent w-100 p-2 ms-2 my-2 text-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <h5 className="fw-bold">داشبورد المدرس</h5>
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse px-2 collapse show bg-transparent w-100"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div
                  className="accordion-body bg-transparent w-100 px-2"
                  style={{ textAlign: "right" }}
                >
                  <DashboardItems
                    setCurrentComponent={setCurrentComponent}
                    setCurrentButton={setCurrentButton}
                    currentButton={currentButton}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-10">
          <div
            className="w-100"
            id="v-pills-1"
            role="tabpanel"
            aria-labelledby="v-pills-1-tab"
          >
            {currentComponent}
          </div>
        </div>
      </div>
    </Animation>
  );
};

export default Dashboard;
