import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { NavLink as Link } from "react-router-dom";
import { endpoints } from "../../hooks/endpoints";
import { getToken } from "../../hooks/utils";
import { useAppSelector } from "../../store/hooks";
import Animation from "../Animation";
import Spinner from "../Spinner";

const Invoices = () => {
  const [invoicesStart, setInvoicesStart] = useState(0);
  const { currentTeacher } = useAppSelector((state) => state.store);

  const fetchInvoices = async () => {
    const res = await (
      await fetch(`${endpoints.invoices}?teacher=${currentTeacher}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        method: "GET",
      })
    ).json();
    return res;
  };

  const { data, isPending, error } = useQuery({
    queryKey: ["invoices"],
    queryFn: fetchInvoices,
    staleTime: 5 * 60 * 1000,
  });

  let invoices = [];

  try {
    invoices =
      data?.status !== 404
        ? data?.filter((invoice) => invoice.cat_slug.includes(currentTeacher))
        : [];
  } catch (error) {
    console.log(error);
  }

  if (isPending) {
    return (
      <Animation>
        <div className="container responsive-pages py-3">
          <h2 className="p-3 my-2 bg-ea-primary rounded-ea text-white">
            مشترياتي
          </h2>
          <div className="table-responsive bg-white responsive-padding-pages-containers rounded-ea shadow p-2">
            <Spinner />
          </div>
        </div>
      </Animation>
    );
  }

  if (!data || invoices?.length < 1 || data?.status === 404) {
    return (
      <Animation>
        <div className="container responsive-pages py-3">
          <h2 className="p-3 my-2 bg-ea-primary rounded-ea text-white">
            مشترياتي
          </h2>
          <div className="table-responsive bg-white responsive-padding-pages-containers rounded-ea shadow p-2">
            <h2 className="text-center">لا يوجد مشتريات</h2>
          </div>
        </div>
      </Animation>
    );
  }

  return (
    <Animation>
      <div className="container responsive-pages py-3">
        <h2 className="p-3 my-2 bg-ea-primary rounded-ea text-white">
          مشترياتي
        </h2>
        <div className="table-responsive bg-white responsive-padding-pages-containers rounded-ea shadow">
          {invoices && (
            <Animation>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">رقم العملية</th>
                    <th scope="col">التاريخ</th>
                    <th scope="col">الحالة</th>
                    <th scope="col">الإجمالي</th>
                    <th scope="col">تفاصيل</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices &&
                    invoices.map(
                      (invoice, index) =>
                        index >= invoicesStart &&
                        index < invoicesStart + 10 && (
                          <tr>
                            <th scope="row">{invoice.id}</th>
                            <td>{invoice.date}</td>
                            <td>
                              {invoice.status === "completed" ? (
                                <p className="text-success fw-bold">تم الدفع</p>
                              ) : invoice.status === "pending" ? (
                                <p className="text-danger fw-bold">
                                  منتظر الدفع
                                </p>
                              ) : (
                                invoice.status
                              )}
                            </td>
                            <td>{invoice.total} جنيه</td>
                            <td>
                              <Link
                                to={`/invoices/${invoice.id}`}
                                style={{
                                  maxHeight: "30px",
                                  padding: "3px 8px",
                                }}
                                className="pt-0 btn ea-btn rounded-ea"
                              >
                                تفاصيل
                              </Link>
                            </td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </Animation>
          )}
          <div className="d-flex">
            {invoices && invoicesStart + 10 < invoices.length && (
              <button
                onClick={() => setInvoicesStart((prev) => prev + 10)}
                className="m-1 btn ea-btn rounded-ea"
              >
                التالي
              </button>
            )}
            {invoices && invoicesStart > 0 && (
              <button
                onClick={() => setInvoicesStart((prev) => prev - 10)}
                className="m-1 btn ea-btn rounded-ea"
              >
                السابق
              </button>
            )}
          </div>
        </div>
      </div>
    </Animation>
  );
};

export default Invoices;
