import { Link } from "react-router-dom";
import Logout from "../../hooks/Logout";
import { useAppSelector } from "../../store/hooks";

const NavbarItems = ({ mainPage }: { mainPage: string }) => {
  const user = useAppSelector((state) => state.store.user);
  return (
    <ul className="navbar-nav fw-bold">
      {user && (
        <>
          <li className="nav-item">
            <Link className="nav-link grow" to={mainPage}>
              الرئيسية
            </Link>
          </li>
          {["teacher", "admin"].includes(user.role) && (
            <li className="nav-item">
              <Link className="nav-link grow" to="/dashboard">
                داشبورد المدرس
              </Link>
            </li>
          )}
          <li className="nav-item">
            <Link className="nav-link grow" to="/account">
              حسابي
            </Link>
          </li>
          <li className="nav-item">
            <Link id="my-quizzes" className="nav-link grow" to="/grades">
              امتحاناتي
            </Link>
          </li>
          <li className="nav-item font-weight-bold">
            <Link id="my-purchases" className="nav-link grow" to="/invoices">
              مشترياتي
            </Link>
          </li>
        </>
      )}
      <li id="log-me-out" className="nav-item me-3">
        {window.location.pathname !== "/" && <Logout text="الخروج" />}
      </li>
    </ul>
  );
};

export default NavbarItems;
