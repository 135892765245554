import useLatestNotifications from "./useLatestNotifications";

interface Notification {
  id: string;
  date: number;
  content: string;
}

const LatestNotifications = () => {
  const { data, isPending, error } = useLatestNotifications();
  return (
    <div className={`my-2 ${isPending ? "loading" : ""}`}>
      <h3 className="text-center">أحدث الإشعارات</h3>
      {error && (
        <div className="alert alert-danger rounded-ea" role="alert">
          {error.message}
        </div>
      )}
      <ul className="list-group p-2 rounded-ea">
        {data?.length > 0 ? (
          data?.map((item: Notification) => (
            <li key={item.id} className="list-group-item">
              <p className="p-0 m-0">{item.content}</p>
              <p className="p-0 m-0 text-secondary fs-6 opacity-75">
                {new Date(item.date * 1000).toLocaleString("ar-EG", {
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  weekday: "long",
                  hour12: true,
                })}
              </p>
            </li>
          ))
        ) : (
          <div
            style={{ width: "fit-content" }}
            className="alert alert-primary mx-auto text-center rounded-ea"
            role="alert"
          >
            لا يوجد إشعارات
          </div>
        )}
      </ul>
    </div>
  );
};

export default LatestNotifications;
