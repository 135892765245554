import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../firebaseConfig";
import { endpoints } from "../../../hooks/endpoints";
import { getFileUrl, getToken } from "../../../hooks/utils";


export const handleUpload = async (
  e,
  setFileProgress,
  setQuestionData,
  questionData
) => {
  if (!e.target.files[0]) {
    alert("Please select a file.");
    return;
  }

  if (!e.target.files[0]) {
    alert("Please select a file.");
    return;
  }

  const imageRef = ref(storage, `questions-${new Date().getTime()}`);
  const uploadTask = uploadBytesResumable(imageRef, e.target.files[0]);

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setFileProgress(progress.toFixed(2));
      console.log("Upload is " + progress + "% done");
    },
    (error) => {
      console.log(error);
      alert("حدث خطأ");
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        // File uploaded successfully
        const tempObject = {};
        tempObject[`quiz_question_img_url_${e.target.dataset.id}`] =
          getFileUrl(downloadURL);
        setQuestionData({ ...questionData, imgSrc: getFileUrl(downloadURL) });
        setFileProgress(null);
      });
    }
  );

  // try {
  //   const formData = new FormData();
  //   formData.append("file", e.target.files[0], e.target.files[0].name);

  //   const xhr = new XMLHttpRequest();

  //   // Track the progress of the upload
  //   xhr.upload.addEventListener("progress", (event) => {
  //     if (event.lengthComputable) {
  //       const percentComplete = (event.loaded / event.total) * 100;
  //       console.log(
  //         "uploadProgress",
  //         `Upload Progress: ${percentComplete.toFixed(2)}%`
  //       );
  //       if (percentComplete.toFixed(2) === "100.00") {
  //         setFileProgress(null);
  //       } else {
  //         setFileProgress(percentComplete.toFixed(2));
  //       }
  //       // You can update a progress bar or display the percentage as needed
  //     }
  //   });

  //   xhr.open("POST", endpoints.aws, true);
  //   // xhr.open("POST", endpoints.imageUpload, true);
  //   // xhr.open("POST", endpoints.eliteUpload, true);

  //   // Add the Authorization header with the token
  //   const token = JSON.parse(
  //     localStorage.getItem(`${window.location.hostname}_user`)
  //   ).token;
  //   xhr.setRequestHeader("Authorization", `Bearer ${token}`);

  //   xhr.onreadystatechange = function () {
  //     if (xhr.readyState === XMLHttpRequest.DONE) {
  //       if (xhr.status === 200) {
  //         try {
  //           const data = JSON.parse(xhr.responseText);

  //           if (data.success) {
  //             // File uploaded successfully
  //             const tempObject = {};
  //             tempObject[`quiz_question_img_url_${e.target.dataset.id}`] =
  //               data.url;
  //             setQuestionData({ ...questionData, imgSrc: data.url });
  //             console.log(data);
  //             setFileProgress(null);
  //           } else {
  //             // File upload failed
  //             alert("File upload failed. " + data.message);
  //             setFileProgress(null);
  //           }
  //         } catch (error) {
  //           alert("حدث خطأ");
  //           setFileProgress(null);
  //         }
  //       } else {
  //         // Server error
  //         alert("حدث خطأ");
  //         setFileProgress(null);
  //       }
  //     }
  //   };

  //   xhr.send(formData);
  // } catch (error) {
  //   // Network error
  //   alert("An error occurred while uploading the file.");
  // }
};

export const getAnswersCount = (questionData) => {
  const answers = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
  const answersOptions = [];
  for (let i = 0; i < questionData.qAnsCount && i < 10; i++) {
    answersOptions.push(answers[i]);
  }
  return answersOptions;
};

export const maxMinCount = (value, setQuestionData) => {
  if (value > 10) {
    setQuestionData((prev) => ({
      ...prev,
      qAnsCount: 10,
    }));
  } else if (value < 2) {
    setQuestionData((prev) => ({
      ...prev,
      qAnsCount: 2,
    }));
  } else {
    setQuestionData((prev) => ({
      ...prev,
      qAnsCount: value,
    }));
  }
};

export const handlePublishQuestion = async (
  setIsLoading,
  questionData,
  setSuccessMsg
) => {
  setIsLoading(true);
  fetch(endpoints.createQuestion, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem(`${window.location.hostname}_user`))
          .token
      }`,
    },
    body: JSON.stringify({
      ...questionData,
      title: `questionData.qCat - ${generateRandomCode(4)}`,
    }),
  })
    .then((res) => res.json())
    .then((data) => {
      setIsLoading(false);
      if (data.success) {
        setSuccessMsg("تم إضافة السؤال بنجاح");
      } else {
        alert("حدث خطأ");
      }
    })
    .catch(() => {
      setIsLoading(false);
      alert("حدث خطأ");
    });
};

export const handleGetBankCategories = async (setTeacherBankCats) => {
  fetch(endpoints.getBankCats, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem(`${window.location.hostname}_user`))
          .token
      }`,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      setTeacherBankCats(data);
    })
    .catch(() => {
      alert("حدث خطأ");
    });
};

export const handleDeleteQuestion = async ({
  qId,
  setLoading,
  setQuestionsData,
  questionsData,
}) => {
  setLoading(true);
  fetch(`${endpoints.deleteBankQuestion}?question=${qId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        JSON.parse(localStorage.getItem(`${window.location.hostname}_user`))
          .token
      }`,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      setLoading(false);
      if (data.error) {
        alert("حدث خطأ");
      } else {
        setQuestionsData(questionsData.filter((q) => q.id !== qId));
      }
    })
    .catch(() => {
      setLoading(false);
      alert("حدث خطأ");
    });
};

const generateRandomCode = (length) => {
  const charset =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomCode = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    randomCode += charset.charAt(randomIndex);
  }

  return randomCode;
};

export const fetchQuestions = async ({
  cat,
  setQuestionsData,
  setIsLoading,
}) => {
  setIsLoading(true);
  fetch(`${endpoints.getBankQuestions}?tag=${cat}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken(),
    },
  })
    .then((res) => res.json())
    .then((data) => {
      setQuestionsData(data);
      setIsLoading(false);
    })
    .catch(() => {
      alert("حدث خطأ");
    });
};

export const addNewBankCategory = async ({
  newCat,
  setIsLoading,
  setResMsg,
}) => {
  setIsLoading(true);
  fetch(`${endpoints.createBankTag}?tag=${newCat}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken(),
    },
  })
    .then((res) => res.json())
    .then((data) => {
      setResMsg(data);
      setIsLoading(false);
    })
    .catch(() => {
      alert("حدث خطأ");
    });
};

export const trimQuestion = ({ paragraph, limit }) => {
  if (paragraph.length > limit) {
    return paragraph.substring(0, limit) + "...";
  } else {
    return paragraph;
  }
};
