import Spinner from "../../Spinner";
import ErrorMsg from "./components/ErrorMsg";
import LessonPageContainer from "./components/LessonPageContainer";
import LessonPageContentArea from "./components/LessonPageContentArea";
import LessonPageContentMapper from "./components/LessonPageContentMapper";
import LessonPageHead from "./components/LessonPageHead";
import LessonPageSidebar from "./components/LessonPageSidebar";
import NotLoggedIn from "./components/NotLoggedIn";
import StudentBlocked from "./components/StudentBlocked";
import useGetLesson from "./hooks/useGetLesson";

const LessonDetails = () => {
  const {
    lessonData,
    isPending,
    error,
    currentContent,
    setCurrentContent,
    isAuthenticated,
    authPending,
    notFinishedPrereq,
    pastLastPurchaseDate,
    isStudentBlocked,
    isMobile,
  } = useGetLesson();

  if (!isAuthenticated) return <NotLoggedIn />;
  if (error) return <ErrorMsg />;
  
  if (isStudentBlocked) return <StudentBlocked />;
  
  if (isPending || authPending) return <Spinner />;

  return (
    <LessonPageContainer>
      <LessonPageContentArea>
        <LessonPageHead lessonData={lessonData} />

        <LessonPageContentMapper
          lessonData={lessonData}
          notFinishedPrereq={notFinishedPrereq}
          pastLastPurchaseDate={pastLastPurchaseDate}
          isMobile={isMobile}
          currentContent={currentContent}
          setCurrentContent={setCurrentContent}
        />
      </LessonPageContentArea>

      <LessonPageSidebar />
    </LessonPageContainer>
  );
};

export default LessonDetails;
