import { useNavigate } from "react-router-dom";
import { userData } from "../store/slices/storeSlice";

import { signOut } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { useAppDispatch } from "../store/hooks";

const Logout = ({ text }: { text: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem(`${window.location.hostname}_user`);
    dispatch(userData(null));
    signOut(auth);
    navigate("/");
  };

  return (
    <span>
      <button
        className="nav-link fw-bold ea-logout-btn grow"
        onClick={handleLogout}
      >
        {text}
      </button>
    </span>
  );
};

export default Logout;
