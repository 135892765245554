import { useState } from "react";
import PWAPrompt from "react-ios-pwa-prompt";
import usePWA from "../../hooks/usePWA";
import { useAppSelector } from "../../store/hooks";

const ButtonsWrapper = () => {
  const [shouldShowPWAPrompt, setShouldShowPWAPrompt] = useState(false);
  const { portal } = useAppSelector((state) => state.store);
  const { installApp } = usePWA();

  return (
    <div className="buttons-wrapper">
      <div className="ios-android">
        <button className="install-btn android" onClick={() => installApp()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            className="bi bi-google-play"
            viewBox="0 0 16 16"
          >
            <path d="M14.222 9.374c1.037-.61 1.037-2.137 0-2.748L11.528 5.04 8.32 8l3.207 2.96zm-3.595 2.116L7.583 8.68 1.03 14.73c.201 1.029 1.36 1.61 2.303 1.055zM1 13.396V2.603L6.846 8zM1.03 1.27l6.553 6.05 3.044-2.81L3.333.215C2.39-.341 1.231.24 1.03 1.27" />
          </svg>
          <span>للأندرويد</span>
        </button>
        <button
          className="install-btn ios"
          onClick={() => setShouldShowPWAPrompt(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            className="bi bi-apple"
            viewBox="0 0 16 16"
          >
            <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
            <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282" />
          </svg>
          <span>للأيفون</span>
        </button>
      </div>
      <div className="windows-mac">
        <button className="install-btn windows" onClick={() => installApp()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            className="bi bi-windows"
            viewBox="0 0 16 16"
          >
            <path d="M6.555 1.375 0 2.237v5.45h6.555zM0 13.795l6.555.933V8.313H0zm7.278-5.4.026 6.378L16 16V8.395zM16 0 7.33 1.244v6.414H16z" />
          </svg>
          <span>للويندوز</span>
        </button>
        <button
          className="install-btn mac"
          onClick={() => installApp(setShouldShowPWAPrompt)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            className="bi bi-command"
            viewBox="0 0 16 16"
          >
            <path d="M3.5 2A1.5 1.5 0 0 1 5 3.5V5H3.5a1.5 1.5 0 1 1 0-3M6 5V3.5A2.5 2.5 0 1 0 3.5 6H5v4H3.5A2.5 2.5 0 1 0 6 12.5V11h4v1.5a2.5 2.5 0 1 0 2.5-2.5H11V6h1.5A2.5 2.5 0 1 0 10 3.5V5zm4 1v4H6V6zm1-1V3.5A1.5 1.5 0 1 1 12.5 5zm0 6h1.5a1.5 1.5 0 1 1-1.5 1.5zm-6 0v1.5A1.5 1.5 0 1 1 3.5 11z" />
          </svg>
          <span>للماك</span>
        </button>
      </div>
      <div className="pwa-popup">
        <PWAPrompt
          copyTitle="تحميل التطبيق"
          copySubtitle={portal?.name}
          copyDescription="اتبع هذه الخطوات لتحميل التطبيق"
          copyShareStep={` إضغط على "مشاركة" أو "Share"`}
          copyAddToHomeScreenStep={`إضغط على "اضافة للشاشة الرئيسية" او "Add to Home Screen"`}
          appIconPath={portal?.logoUrl}
          isShown={shouldShowPWAPrompt}
          onClose={() => setShouldShowPWAPrompt(false)}
          delay={1}
        />
      </div>
      {/* <IosDownloadPopup
        isActive={shouldShowPWAPrompt}
        toggleFn={setShouldShowPWAPrompt}
      /> */}
    </div>
  );
};

export default ButtonsWrapper;
