import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { removeNotification } from "../../store/slices/storeSlice";
import Notification from "./Notification";

const NotificationsDrawer = () => {
  const { notifications } = useAppSelector((state) => state.store);
  const [showNotification, setShowNotification] = useState(false);
  const dispatch = useAppDispatch();
  // const resetNotification = () => {
  //   setShowNotification(false);
  //   setTimeout(() => {
  //     dispatch(setNotification(null));
  //   }, 1000);
  // };

  // useEffect(() => {
  //   if (notifications) {
  //     setShowNotification(true);
  //   }
  // }, [notifications]);

  return (
    <div className={`elite-notification`}>
      {[...notifications]?.sort((a, b) => b.ID - a.ID).map((notification) => (
        <Notification
          title={notification.title}
          body={notification.body}
          resetNotification={() =>
            dispatch(removeNotification(notification.ID))
          }
        />
      ))}
    </div>
  );
};

export default NotificationsDrawer;
