import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "bootstrap/dist/js/bootstrap.esm";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "../node_modules/bootstrap/dist/css/bootstrap.rtl.min.css";
import App from "./components/App";
import "./sentry";
import { store } from "./store/store";
import "./styles/index.css";
import "./styles/mobile-navbar.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <GoogleOAuthProvider clientId="958297622504-nddc810btttg3g9l8c4k7717pqcmtbgv.apps.googleusercontent.com">
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>
    </GoogleOAuthProvider>
  </QueryClientProvider>
);

//

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
