import { useQuery } from "@tanstack/react-query";
import { endpoints } from "../../../../hooks/endpoints";
import { getToken } from "../../../../hooks/utils";

interface NotificationReceivedI {
  id: string;
  date: string;
  content: string;
}

const useLatestNotifications = () => {
  const getNotifications = async () => {
    try {
      const res = await (
        await fetch(`${endpoints.getNotifications}?portal=${window.location.hostname}&rand_key=${Math.random()}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: getToken(),
          },
        })
      ).json();
      if (!res.data) return [];
      return res.data as NotificationReceivedI[];
    } catch (error) {
      return error;
    }
  };

  const { data, isPending, error } = useQuery({
    queryKey: ["notifications"],
    queryFn: () => getNotifications(),
    staleTime: Infinity,
  });

  return {
    data,
    isPending,
    error,
  };
};

export default useLatestNotifications;
