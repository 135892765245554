import { isTeacher } from "../../../hooks/utils";
import { User } from "../../../store/slices/storeSlice";
import AccountBtn from "./components/AccountBtn";
import DashboardBtn from "./components/DashboardBtn";
import GradesBtn from "./components/GradesBtn";
import HomeBtn from "./components/HomeBtn";
import InvoicesBtn from "./components/InvoicesBtn";

const MobileNavbar = ({ user, mainPage }: { user: User; mainPage: string }) => {
  if (!user) return <></>;

  return (
    <div className="mobile-navbar">
      <HomeBtn mainPage={mainPage} />
      {isTeacher() && <DashboardBtn />}
      <AccountBtn />
      <GradesBtn />
      <InvoicesBtn />
    </div>
  );
};

export default MobileNavbar;
