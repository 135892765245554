import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useEffect, useState } from "react";
import { NavLink as Link } from "react-router-dom";
import { storage } from "../../firebaseConfig";
import { endpoints } from "../../hooks/endpoints";
import { getFileUrl, getToken } from "../../hooks/utils";
import createPreview from "../../scripts/createPreview";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  invokePortalRefetch,
  invokeUserRefetch,
} from "../../store/slices/storeSlice";
import Animation from "../Animation";
import Separator from "../Separator";
import Spinner from "../Spinner";
import AttachAttendanceReport from "./AttachAttendanceReport/AttachAttendanceReport";
import { payment_methods } from "./CreateLesson";
import FileUploadProgressBar from "./FileUploadProgressBar";

const EditLesson = () => {
  const [populatedVideos, setPopulatedVideos] = useState(false);
  const [videosCount, setVideosCount] = useState([1]);
  const [filesCount, setFilesCount] = useState([1]);
  const [lessonFiles, setLessonFiles] = useState([]);
  const [successMsg, setSuccessMsg] = useState(null);
  const [fileProgress, setFileProgress] = useState(null);
  const [preType, setPreType] = useState(null);
  const [xvast, setXvast] = useState(null);
  const [quizIsRequired, setQuizIsRequired] = useState(null);
  const [gotCode, setGotCode] = useState(null);
  const [gotWallet, setGotWallet] = useState(null);
  const [gotFawry, setGotFawry] = useState("off");
  const [gotVodaCash, setGotVodaCash] = useState(false);
  const [price, setPrice] = useState(false);
  const [views, setViews] = useState(false);
  const [lessonTime, setLessonTime] = useState(false);

  const [lesTitle, setLesTitle] = useState(null);
  const [quiz, setQuiz] = useState(null);
  const [hw, setHw] = useState(null);
  const [cat, setCat] = useState(null);
  const [changeVideos, setChangeVideos] = useState(true);
  const [publishing, setPublishing] = useState(false);
  const [lessonType, setLessonType] = useState("yes");
  const [loading, setLoading] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);
  // const [lessons, setLessons] = useState(null);
  const [lessonData, setLessonData] = useState(null);
  const [prereq, setPrereq] = useState(null);
  const [lastPurchaseDate, setLastPurchaseDate] = useState();
  const [activePaymentMethods, setActivePaymentMethods] = useState({
    fawry: false,
    vodafone_cash: false,
    wallet: false,
    code: false,
  });

  const { userProfileData, portal } = useAppSelector((state) => state.store);
  const lessons = userProfileData?.hws_quizzes_lessons?.filter(
    (item) => item.post_type === "product"
  );
  const quizzes = userProfileData?.hws_quizzes_lessons?.filter(
    (item) => item.post_type === "quiz"
  );
  const homeworks = userProfileData?.hws_quizzes_lessons?.filter(
    (item) => item.post_type === "homework"
  );

  // const lesson = portal.data.find((item) => item.ID === selectedLesson);
  const dispatch = useAppDispatch();

  const handeGetLessonData = (e) => {
    setPopulatedVideos(false);
    setSuccessMsg(null);
    setLoading(true);
    setLessonFiles([]);
    setFilesCount([1]);
    setSelectedLesson(e.target.value);
    fetch(`${endpoints.editGetLesson}?lesson=${e.target.value}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((res) => {
        // const data = {
        //   cat: lesson.slug,
        //   meta_fields: res,
        //   createLessonData: {
        //     homeworks,
        //     quizzes,
        //     lessons,
        //     payment_methods: userProfileData.allowed_payment_methods,
        //     categories: userProfileData.categories,
        //   },
        //   title: lesson.post_title,
        // };
        setLessonData({
          cat: portal.data.find((item) => item.ID === e.target.value).slug,
          meta_fields: res,
          createLessonData: {
            homeworks,
            quizzes,
            lessons,
            payment_methods: userProfileData.allowed_payment_methods,
            categories: userProfileData.categories,
          },
          title: portal.data.find((item) => item.ID === e.target.value)
            .post_title,
        });

        setLoading(false);
        setLesTitle(
          portal.data.find((item) => item.ID === e.target.value).post_title
        );
        setQuiz(+res.quiz_id[0]);
        setHw(+res.hw_id[0]);
        setCat(portal.data.find((item) => item.ID === e.target.value).slug);
        setXvast(res.xvast_protection[0] === "yes" ? true : false);
        setQuizIsRequired(res.quiz_required[0] === "yes" ? true : false);
        setGotCode(res.payment_method_code[0] === "yes" ? true : false);
        setGotWallet(res.payment_method_wallet[0] === "yes" ? true : false);
        setGotFawry(res.payment_method_fawry[0] === "yes" ? true : false);
        setGotVodaCash(
          res.payment_method_vodafone_cash[0] === "yes" ? true : false
        );
        setActivePaymentMethods({
          fawry: res.payment_method_fawry[0] === "yes" ? true : false,
          vodafone_cash:
            res.payment_method_vodafone_cash[0] === "yes" ? true : false,
          wallet: res.payment_method_wallet[0] === "yes" ? true : false,
          code: res.payment_method_code[0] === "yes" ? true : false,
        });
        setPrice(+res._regular_price[0]);
        setViews(
          res.allowed_views[0] === "infinite" ? 0 : +res.allowed_views[0]
        );
        setLessonTime(
          res.allowed_time[0] === "infinite"
            ? 0
            : +res.allowed_time[0] / 86400000
        );
        setPrereq(() => {
          try {
            const pre = res.prerequisites[0];
            return pre;
          } catch (e) {
            console.log(e);
            return null;
          }
        });
        setVideosCount(() => {
          try {
            const videos = JSON.parse(res.videos_urls_titles[0]);
            return videos;
          } catch (e) {
            console.log(e);
            return [];
          }
        });
        setLastPurchaseDate(() => {
          try {
            const date = res.last_purchase_date[0];
            return date;
          } catch (e) {
            console.log(e);
            return null;
          }
        });
        if (res.lesson_files) {
          const filesArr = [];
          for (let i = 1; i <= res.lesson_files?.length; i++) {
            filesArr.push(i);
          }
          setFilesCount([...filesArr]);
          setLessonFiles([...res.lesson_files]);
        }
      })
      .catch((err) => {
        setLessonData(err);
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   fetch(`${endpoints.teacherLessons}?timestamp=${Date()}`, {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: getToken(),
  //     },
  //     method: "GET",
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setLessons(data);
  //     })
  //     .catch((err) => {
  //       setLessons(null);
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    if (!populatedVideos) {
      videosCount?.forEach((video, index) => {
        const vidTitle = document.getElementById(`vid_quiz_title_${index}`);
        if (vidTitle) {
          vidTitle.value = video.video_title;
        }
        const vidUrl = document.getElementById(`vid_url_${index}`);
        if (vidUrl) {
          vidUrl.value = `https://www.youtube.com/watch?v=${video.video_id}`;
        }
      });
      setPopulatedVideos(true);
    }
  }, [videosCount]);

  const handleFileUploadFb = (e) => {
    if (!e.target.files[0]) {
      alert("Please select a file.");
      return;
    }

    const imageRef = ref(storage, `files-${new Date().getTime()}`);
    const uploadTask = uploadBytesResumable(imageRef, e.target.files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setFileProgress(progress.toFixed(2));
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        console.log(error);
        alert("حدث خطأ");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // File uploaded successfully
          setLessonFiles((prev) => [...prev, getFileUrl(downloadURL)]);
          setFileProgress(null);
        });
      }
    );
  };

  // const handleFileUpload = async (e) => {
  //   if (!e.target.files[0]) {
  //     alert("Please select a file.");
  //     return;
  //   }

  //   try {
  //     const formData = new FormData();
  //     formData.append("file", e.target.files[0], e.target.files[0].name);

  //     const xhr = new XMLHttpRequest();

  //     // Track the progress of the upload
  //     xhr.upload.addEventListener("progress", (event) => {
  //       if (event.lengthComputable) {
  //         const percentComplete = (event.loaded / event.total) * 100;
  //         console.log(
  //           "uploadProgress",
  //           `Upload Progress: ${percentComplete.toFixed(2)}%`
  //         );
  //         if (percentComplete.toFixed(2) === "100.00") {
  //           setFileProgress(null);
  //         } else {
  //           setFileProgress(percentComplete.toFixed(2));
  //         }
  //         // You can update a progress bar or display the percentage as needed
  //       }
  //     });

  //     // xhr.open("POST", endpoints.imageUpload, true);
  //     xhr.open("POST", endpoints.aws, true);

  //     // Add the Authorization header with the token
  //     const token = JSON.parse(
  //       localStorage.getItem(`${window.location.hostname}_user`)
  //     ).token;
  //     xhr.setRequestHeader("Authorization", `Bearer ${token}`);

  //     xhr.onreadystatechange = function () {
  //       if (xhr.readyState === XMLHttpRequest.DONE) {
  //         if (xhr.status === 200) {
  //           try {
  //             const data = JSON.parse(xhr.responseText);

  //             if (data.success) {
  //               // File uploaded successfully
  //               setLessonFiles((prev) => [...prev, data.url]);
  //               console.log(data);
  //               setFileProgress(null);
  //             } else {
  //               // File upload failed
  //               alert("File upload failed. " + data.message);
  //               setFileProgress(null);
  //             }
  //           } catch (error) {
  //             alert("حدث خطأ");
  //             setFileProgress(null);
  //           }
  //         } else {
  //           // Server error
  //           alert("حدث خطأ");
  //           setFileProgress(null);
  //         }
  //       }
  //     };

  //     xhr.send(formData);
  //   } catch (error) {
  //     // Network error
  //     alert("An error occurred while uploading the file.");
  //   }
  // };

  const handleSubmitLesson = async (e) => {
    e.preventDefault();
    setPublishing(true);
    try {
      const formData = new FormData(e.target);
      const formDataObject = {};
      for (const [key, value] of formData.entries()) {
        formDataObject[key] = value;
      }
      // formDataObject["lessonFiles"] = JSON.stringify(lessonFiles);
      formDataObject["lessonFiles"] = lessonFiles;

      if (formDataObject["vid_url_1"]) {
        formDataObject["video_host"] = formDataObject["vid_url_1"].includes(
          "youtu"
        )
          ? "youtube"
          : formDataObject["vid_url_1"].includes("vimeo")
          ? "vimeo"
          : "";
        const vids_data = [];
        videosCount.map((vid, index) => {
          const vid_id = formDataObject[`vid_url_${index}`].includes(
            "https://youtu.be/"
          )
            ? formDataObject[`vid_url_${index}`].replace(
                "https://youtu.be/",
                ""
              )
            : formDataObject[`vid_url_${index}`].includes("youtube.com/watch")
            ? formDataObject[`vid_url_${index}`].replace(
                "https://www.youtube.com/watch?v=",
                ""
              )
            : "";
          vids_data.push({
            video_title: formDataObject[`vid_quiz_title_${index}`],
            video_id: vid_id,
          });
        });
        formDataObject["videos_urls_titles"] = JSON.stringify(vids_data);
      }
      formDataObject["lesson"] = selectedLesson;

      console.log("formDataObject", formDataObject);

      if (!["quiz", "hw"].includes(formDataObject["prereq_type"])) {
        formDataObject["prereq_type"] = "";
        formDataObject["prereq"] = "";
      }

      const response = await fetch(endpoints.editLesson, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        body: JSON.stringify(formDataObject),
      });

      if (!response.ok) {
        // Handle server errors or other issues
        console.error("Error submitting the form");
        return;
      } else {
        const successInfo = await response.json();
        setPublishing(false);
        setSuccessMsg(successInfo);
        dispatch(invokePortalRefetch());
        dispatch(invokeUserRefetch());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteLesson = () => {
    fetch(endpoints.deleteLesson, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      body: JSON.stringify({ lesson: selectedLesson }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.success) {
          dispatch(invokePortalRefetch());
          dispatch(invokeUserRefetch());
        }
      });
  };

  return (
    <Animation>
      <div className="create-lesson">
        <form
          onSubmit={(e) => handleSubmitLesson(e)}
          className="m-3 p-3 bg-white rounded-ea shadow"
        >
          <h3 className="mt-3 mb-4 bg-ea-primary p-3 text-white rounded-ea shadow fw-bold">
            تعديل حصة
          </h3>
          <select
            onChange={handeGetLessonData}
            className="form-select rounded-ea my-3"
            aria-label="Default select example"
          >
            <option selected>إختر الحصة</option>
            {lessons &&
              lessons?.map((lesson) => (
                <option value={lesson.ID}>{lesson.post_title}</option>
              ))}
          </select>
          {lessonData && lessonData.createLessonData && !loading ? (
            <>
              <button
                type="button"
                className="btn btn-danger mb-3 mt-2 rounded-ea text-white px-3 py-1"
                onClick={() =>
                  confirm("هل تريد حذف هذه الحصة؟") && deleteLesson()
                }
              >
                مسح الحصة من المنصة
              </button>

              {/* <button
                type="button"
                className="btn btn-danger mb-3 mt-2 rounded-ea text-white px-3 py-1"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                مسح الحصة من المنصة
              </button>

              <div className="text-center">
                <div
                  className="modal fade w-75"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          مسح الحصة
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        هل حقا تريد مسح هذه الحصة؟
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          الرجوع
                        </button>
                        <button type="button" className="btn btn-primary">
                          مسح الحصة
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="form-floating mb-3">
                <input
                  onChange={(e) => setLesTitle(e.target.value)}
                  type="text"
                  className="form-control rounded-ea"
                  id="lesson_title"
                  name="lesson_title"
                  placeholder="lesson_title"
                  value={lesTitle}
                  required
                />
                <label htmlFor="lesson_title">إسم الحصة</label>
              </div>
              <select
                name="video_on_off"
                onChange={(e) => setLessonType(e.target.value)}
                value={lessonType}
                className="form-select mb-3 rounded-ea"
                aria-label="نوع الحصة"
              >
                <option selected>نوع الحصة</option>
                <option value="yes">فيديو</option>
                <option value="no">إمتحان</option>
              </select>
              {changeVideos &&
                lessonType === "yes" &&
                videosCount?.map((video, index) => (
                  <>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control rounded-ea"
                        id={`vid_quiz_title_${index}`}
                        name={`vid_quiz_title_${index}`}
                        placeholder={`vid_quiz_title_${index}`}
                        required
                      />
                      <label htmlFor={`vid_quiz_title_${index}`}>
                        عنوان الفيديو أو الإمتحان
                      </label>
                    </div>
                    <div id={`vid_url_${index}_preview_div`}>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control rounded-ea"
                          id={`vid_url_${index}`}
                          name={`vid_url_${index}`}
                          placeholder={`vid_url_${index}`}
                          onChange={(e) => createPreview(e)}
                          required
                        />
                        <label htmlFor={`vid_url_${index}`}>لينك الفيديو</label>
                      </div>
                      <iframe
                        id={`vid_url_${index}_preview`}
                        style={{ minHeight: "260px" }}
                        className="responsive-vid-preview"
                        src={`https://www.youtube.com/embed/${video.video_id}`}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </>
                ))}
              {changeVideos &&
                videosCount.length > 1 &&
                lessonType === "yes" && (
                  <div id="ls_remove_vid_url" style={{ marginBottom: "10px" }}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        setVideosCount((prev) => [
                          ...prev.filter(
                            (val) => val !== prev[prev.length - 1]
                          ),
                        ]);
                      }}
                      id="ls_remove_vid_url_btn"
                      className="btn ea-btn rounded-ea"
                      style={{
                        minWidth: "13%",
                        borderRadius: "7px",
                        fontWeight: "bold",
                      }}
                    >
                      - مسح فيديو
                    </button>
                  </div>
                )}
              {changeVideos && lessonType === "yes" && (
                <div id="ls_add_vid_url" style={{ marginBottom: "10px" }}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setVideosCount((prev) => [...prev, prev.length + 1]);
                    }}
                    id="ls_add_vid_url_btn"
                    className="btn ea-btn rounded-ea"
                    style={{
                      minWidth: "13%",
                      borderRadius: "7px",
                      fontWeight: "bold",
                    }}
                  >
                    + إضافة فيديو
                  </button>
                </div>
              )}
              <Separator />
              {filesCount?.map((question, index) => (
                <>
                  {fileProgress &&
                    fileProgress < 100 &&
                    fileProgress >= 0 &&
                    index + 1 === filesCount.length && (
                      <FileUploadProgressBar fileProgress={fileProgress} />
                    )}
                  {/* <label
                  className="form-label"
                  htmlFor={`lesson_file_input_${question}`}
                >
                  رفع ملف
                </label> */}
                  <div className="border-ea bg-ea-base-2 rounded-ea my-2 pt-3">
                    {lessonFiles[index] ? (
                      <div style={{ textAlign: "center" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          fill="currentColor"
                          className="bi bi-file-earmark-text-fill text-ea-primary"
                          viewBox="0 0 16 16"
                        >
                          <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1z" />
                        </svg>
                        <span>ملف رقم {index + 1}</span>
                      </div>
                    ) : (
                      <input
                        style={{ textAlign: "center" }}
                        id={`lesson_file_input_${question}`}
                        onChange={(e) => handleFileUploadFb(e)}
                        data-id={question}
                        className="elite-file lesson_form_input form-control mt-2"
                        type="file"
                        placeholder="hey"
                        name={`lesson_file_input_${question}`}
                      />
                    )}
                    <br />
                  </div>
                </>
              ))}
              {filesCount.length > 0 && (
                <div id="ls_remove_file_input" style={{ marginBottom: "10px" }}>
                  <button
                    disabled={
                      fileProgress && fileProgress < 100 && fileProgress >= 0
                        ? true
                        : false
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setFilesCount((prev) => [
                        ...prev.filter((val) => val !== prev[prev.length - 1]),
                      ]);
                      if (lessonFiles.length === filesCount.length)
                        setLessonFiles(lessonFiles.slice(0, -1));
                    }}
                    id="ls_remove_file_input_btn"
                    className="btn ea-btn rounded-ea"
                    style={{
                      minWidth: "15%",
                      borderRadius: "7px",
                      fontWeight: "bold",
                    }}
                  >
                    - مسح ملف
                  </button>
                </div>
              )}
              <div id="ls_add_file_input" style={{ marginBottom: "10px" }}>
                <button
                  disabled={
                    (fileProgress && fileProgress < 100 && fileProgress >= 0) ||
                    lessonFiles.length !== filesCount.length
                      ? true
                      : false
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setFilesCount((prev) => [...prev, prev.length + 1]);
                  }}
                  id="ls_add_file_input_btn"
                  className="btn ea-btn rounded-ea"
                  style={{
                    minWidth: "15%",
                    borderRadius: "7px",
                    fontWeight: "bold",
                  }}
                >
                  + إضافة ملف
                </button>
              </div>
              <Separator />
              <div className="form-check form-switch my-3">
                <input
                  checked={xvast}
                  onChange={(e) => setXvast(e.target.checked)}
                  className="form-check-input"
                  type="checkbox"
                  id="xvast_protection"
                  name="xvast_protection"
                />
                <label
                  className="form-check-label ms-1"
                  htmlFor="xvast_protection"
                >
                  تفعيل الحماية (الطالب لازم ينزل البرنامج عشان يشوف الحصة)
                </label>
              </div>
              <div className="form-check form-switch my-3">
                <input
                  checked={quizIsRequired}
                  onChange={(e) => setQuizIsRequired(e.target.checked)}
                  className="form-check-input"
                  type="checkbox"
                  id="quiz_required"
                  name="quiz_required"
                />
                <label
                  className="form-check-label ms-1"
                  htmlFor="quiz_required"
                >
                  يجب دخول الكويز قبل مشاهدة الحصة
                </label>
              </div>
              <Separator />
              <p className="fw-bold my-2">طرق الدفع</p>
              {userProfileData?.allowed_payment_methods?.map((method) => (
                <div className="form-check form-switch my-3">
                  <input
                    checked={activePaymentMethods[method]}
                    onChange={(e) =>
                      setActivePaymentMethods((prev) => ({
                        ...prev,
                        [method]: e.target.checked,
                      }))
                    }
                    className="form-check-input"
                    type="checkbox"
                    id={`payment_method_${method}`}
                    name={`payment_method_${method}`}
                  />
                  <label
                    className="form-check-label ms-1"
                    htmlFor={`payment_method_${method}`}
                  >
                    {payment_methods[method]}
                  </label>
                </div>
              ))}
              {/* {lessonData?.createLessonData?.allowed_payment_methods?.includes(
                  "wallet"
                ) && (
                  <div className="form-check form-switch my-3">
                    <input
                      checked={gotWallet}
                      onChange={(e) => setGotWallet(e.target.checked)}
                      className="form-check-input"
                      type="checkbox"
                      id="payment_method_wallet"
                      name="payment_method_wallet"
                    />
                    <label
                      className="form-check-label ms-1"
                      htmlFor="payment_method_wallet"
                    >
                      رصيد المحفظة
                    </label>
                  </div>
                )}
              {lessonData?.createLessonData?.allowed_payment_methods?.includes(
                  "fawry"
                ) && (
                  <div className="form-check form-switch my-3">
                    <input
                      checked={gotFawry}
                      onChange={(e) => setGotFawry(e.target.checked)}
                      className="form-check-input"
                      type="checkbox"
                      id="payment_method_fawry"
                      name="payment_method_fawry"
                    />
                    <label
                      className="form-check-label ms-1"
                      htmlFor="payment_method_fawry"
                    >
                      فوري
                    </label>
                  </div>
                )}
              {lessonData?.createLessonData?.allowed_payment_methods?.includes(
                  "vodafone_cash"
                ) && (
                  <div className="form-check form-switch my-3">
                    <input
                      checked={gotVodaCash}
                      onChange={(e) => setGotVodaCash(e.target.checked)}
                      className="form-check-input"
                      type="checkbox"
                      id="payment_method_vodafone_cash"
                      name="payment_method_vodafone_cash"
                    />
                    <label
                      className="form-check-label ms-1"
                      htmlFor="payment_method_vodafone_cash"
                    >
                      فودافون كاش
                    </label>
                  </div>
                )} */}
              <Separator />
              <div className="form mb-3">
                <label className="mb-1" htmlFor="price">
                  السعر (لو مجانية اكتب 0)
                </label>
                <input
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  type="number"
                  className="form-control rounded-ea"
                  id="price"
                  name="price"
                  placeholder="price"
                  required
                />
              </div>
              <div className="form mb-3">
                <label className="mb-1" htmlFor="allowed_views">
                  عدد المشاهدات المتاحة لكل طالب (لو مفتوح اكتب 0)
                </label>
                <input
                  value={views}
                  onChange={(e) => setViews(e.target.value)}
                  type="number"
                  className="form-control rounded-ea"
                  id="allowed_views"
                  name="allowed_views"
                  placeholder="allowed_views"
                  required
                />
              </div>
              <div className="form mb-3">
                <label className="mb-1" htmlFor="allowed_time">
                  عدد أيام صلاحية الحصة بعد الشراء (لو مفتوح اكتب 0)
                </label>
                <input
                  value={lessonTime}
                  onChange={(e) => setLessonTime(e.target.value)}
                  type="number"
                  className="form-control rounded-ea"
                  id="allowed_time"
                  name="allowed_time"
                  placeholder="allowed_time"
                  required
                />
              </div>
              <label className="mb-1" htmlFor="last_purchase_date">
                اخر ميعاد لشراء الحصة (إختياري)
              </label>
              <div className="form mb-3">
                <input
                  value={lastPurchaseDate}
                  onChange={(e) => setLastPurchaseDate(e.target.value)}
                  type="datetime-local"
                  className="form-control rounded-ea w-100"
                  id="last_purchase_date"
                  name="last_purchase_date"
                  placeholder="last_purchase_date"
                />
              </div>
              <Separator />
              <select
                onChange={(e) => setCat(e.target.value)}
                value={cat}
                name="prod_cat"
                className="form-select mb-3 rounded-ea"
                aria-label="المرحلة أو القسم"
              >
                <option disabled selected>
                  المرحلة أو القسم
                </option>
                {portal?.cats
                  .filter((item) => item.parent !== 0)
                  ?.map((cat) => (
                    <option value={cat.slug}>
                      {cat.name.indexOf("|") === -1
                        ? cat.name
                        : cat.name.split("|")[0]}
                    </option>
                  ))}
              </select>
              <select
                onChange={(e) => setQuiz(e.target.value)}
                value={quiz}
                name="quiz_id"
                className="form-select mb-3 rounded-ea"
                aria-label="الكويز"
              >
                <option selected value="">
                  الكويز
                </option>
                {lessonData?.createLessonData?.quizzes?.map((quiz) => (
                  <option value={quiz.ID}>{quiz.post_title}</option>
                ))}
              </select>
              <select
                onChange={(e) => setHw(e.target.value)}
                value={hw}
                name="hw_id"
                className="form-select mb-3 rounded-ea"
                aria-label="الواجب"
              >
                <option selected value="">
                  الواجب
                </option>
                {lessonData?.createLessonData?.homeworks?.map((homework) => (
                  <option value={homework.ID}>{homework.post_title}</option>
                ))}
              </select>
              <Separator />
              <p className="my-2">
                يجب على الطالب حل واجب أو كويز حصة معينة قبل شراء هذه الحصة
              </p>
              {/* <select
                value={prereq}
                onChange={(e) => setPrereq(e.target.value)}
                name="prereq"
                className="form-select mb-3 rounded-ea"
                aria-label="يجب على الطالب حل واجب حصة معينة قبل شراء هذه الحصة"
              >
                <option selected>إختر الحصة</option>
                {lessons?.map((lesson) => (
                  <option value={lesson.ID}>{lesson.post_title}</option>
                ))}
              </select> */}
              <select
                onChange={(e) => setPreType(e.target.value)}
                name="prereq_type"
                className="form-select mb-3 rounded-ea"
                aria-label="يجب على الطالب حل واجب أو كويز حصة معينة قبل شراء هذه الحصة"
              >
                <option selected>إختر</option>
                <option value="quiz">نعم يجب حل الكويز</option>
                <option value="hw">نعم يجب حل الواجب</option>
              </select>
              {preType && (
                <select
                  value={prereq}
                  onChange={(e) => setPrereq(e.target.value)}
                  name="prereq"
                  className="form-select mb-3 rounded-ea"
                  aria-label="choose-prereq"
                >
                  <option disabled selected>
                    إختر الحصة
                  </option>
                  {userProfileData?.hws_quizzes_lessons
                    ?.filter((item) => item.post_type === "product")
                    ?.map((lesson) => (
                      <option value={lesson.ID}>{lesson.post_title}</option>
                    ))}
                </select>
              )}
              <Separator />
              <AttachAttendanceReport />
              {!publishing && (
                <input
                  disabled={
                    fileProgress && fileProgress < 100 && fileProgress >= 0
                      ? true
                      : false
                  }
                  type="submit"
                  id="publish_new_quiz_submit"
                  className="ea-btn btn w-100 rounded-ea"
                  value="حفظ"
                />
              )}
              {publishing && (
                <button
                  className="ea-btn btn w-100 rounded-ea"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  جاري حفظ الحصة...
                </button>
              )}
              {successMsg && (
                <div className="alert alert-success h-50 mt-2" role="alert">
                  تم الحفظ{" "}
                  <Link
                    to={`/category/${successMsg.teacher_slug}/${successMsg.lesson_slug}`}
                  >
                    إضغط هنا للذهاب للحصة
                  </Link>
                </div>
              )}
            </>
          ) : !selectedLesson ? (
            ""
          ) : (
            <Spinner />
          )}
        </form>
      </div>
    </Animation>
  );
};

export default EditLesson;
