import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore/lite";
import { getMessaging } from "firebase/messaging";
import { getRemoteConfig } from "firebase/remote-config";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDRKG40ff8S3qfjVHit3KB0gvYC2WRPmPE",
  authDomain: "eliteacademyeg-a7552.firebaseapp.com",
  projectId: "eliteacademyeg-a7552",
  storageBucket: "eliteacademyeg-a7552.appspot.com",
  messagingSenderId: "958297622504",
  appId: "1:958297622504:web:7bdd5201c7c93f6422465d",
  measurementId: "G-BNV4PJQZJ1",
};

export const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
export const messaging = getMessaging(app);
export const remoteConfig = getRemoteConfig(app);

remoteConfig.settings.minimumFetchIntervalMillis = 600000;
