import { Link, useLocation } from "react-router-dom";

const AccountBtn = () => {
  const { pathname } = useLocation();

  const active = "/account" === pathname;

  return (
    <div className="d-flex flex-column gap-1 align-items-center">
      <Link to="/account">
        <svg
          style={{
            transition: "all 0.2s ease-in-out",
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          fill="currentColor"
          className={`bi bi-person fs-3 fw-bold p-2 rounded-ea m-0 ${
            active ? "text-white bg-ea-primary" : "text-ea-primary bg-white"
          }`}
          viewBox="0 0 16 16"
        >
          <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z" />
        </svg>
      </Link>
      <p className="text-center m-0 p-0 text-ea-primary" style={{ fontSize: "0.8rem" }}>
        حسابي
      </p>
    </div>
  );
};

export default AccountBtn;
