import Animation from "../../Animation";
import Separator from "../../Separator";
import LatestNotifications from "./LatestNotifications/LatestNotifications";
import SendNotification from "./SendNotification/SendNotification";

const NotificationsDash = () => {
  return (
    <Animation>
      <div className="m-3 p-3 bg-white rounded-ea shadow">
        <h3 className="mt-3 mb-4 bg-ea-primary p-3 text-white rounded-ea shadow fw-bold">
          إرسال إشعار للطلاب
        </h3>
        <SendNotification />
        <Separator />
        <LatestNotifications />
      </div>
    </Animation>
  );
};

export default NotificationsDash;
