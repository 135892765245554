import * as sentry from "@sentry/browser";

export enum ErrorTags {
  LessonScreen = "lesson_screen",
  SubmitFcmInfo = "submit_fcm_info",
}

export const reportAppError = ({
  e,
  info,
  errorTag,
}: {
  e: any;
  info: string;
  errorTag?: ErrorTags;
}) => {
  console.error(info, e);
  console.trace();
  sentry.captureException(e, {
    tags: {
      errorTag,
    },
    extra: {
      info,
    },
  });
};
