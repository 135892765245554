import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { endpoints } from "../../../../hooks/endpoints";
import { getToken } from "../../../../hooks/utils";
import { useAppSelector } from "../../../../store/hooks";

interface ResponseI {
  status: number;
  message: string;
  data: any;
}

const useSendNotifications = () => {
  const queryClient = useQueryClient();
  const [data, setData] = useState<ResponseI>();
  const [isPending, setPending] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const notificationRef = useRef<HTMLTextAreaElement>(null);
  const { userProfileData } = useAppSelector((state) => state.store);

  const sendNotification = async () => {
    setPending(true);
    try {
      const res = await (
        await fetch(endpoints.sendNotification, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: getToken(),
          },
          body: JSON.stringify({
            topic: window.location.hostname,
            data: {
              portal: window.location.hostname,
            },
            notification: {
              title: `أ. ${userProfileData.display_name}`,
              body: notificationRef?.current?.value ?? "",
            },
          }),
        })
      ).json();
      setData(res);
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
    } catch (error) {
      setError(error.message as string);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
    if (data) {
      setTimeout(() => {
        setData(null);
      }, 5000);
    }
  }, [error, data]);

  return {
    data,
    notificationRef,
    error,
    isPending,
    sendNotification,
  };
};

export default useSendNotifications;
