export const setTeacherColorScheme = (teacherDomain) => {
  const teachersSchemes = {
    localhost: {
      "--primary-color": "#673de6",
      // "--primary-color": "green",
      "--secondary-color": "#491cd5",
      "--bg-ea-base": "#d9cef9 !important",
      "--bg-ea-base-2": "#e5dff7 !important",
      "--bg-ea-success": "#44d576 !important",
      "--bg-ea-danger": "#ee6565 !important",
      "--bg-ea-gray": "#cacaca !important",
      "--bg-eee": "#eee !important",
      "--bg-ddd": "#ddd !important",
      "--bg-half-gray": "#c5c1c1 !important",
      "--bg-aaa": "#aaa !important",
    },
  };

  const rootElement = document.documentElement;
  const teacher = teachersSchemes[teacherDomain] ?? teachersSchemes.localhost;
  const keys = Object.keys(teacher);
  keys.forEach((key) => {
    rootElement.style.setProperty(key, teacher[key]);
  });
};
