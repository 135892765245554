// import './index.css';
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { remoteConfig } from "../firebaseConfig";
import "../firebaseConfig.ts";
import { endpoints } from "../hooks/endpoints";
import useCheckForUpdates from "../hooks/useCheckForUpdates";
import useNotifications from "../hooks/useNotifications";
import usePWA from "../hooks/usePWA";
import { isTeacher } from "../hooks/utils";
import { setTeacherColorScheme } from "../scripts/setTeacherColorScheme";
import { useAppSelector } from "../store/hooks";
import AccountPage from "./account/AccountPage";
import AiAssistant2 from "./ai-assistant/AiAssistant2";
import Animation from "./Animation";
import CompleteInfo from "./complete-info/CompleteInfo";
import Dashboard from "./dashboard/Dashboard";
import FileUpload from "./FileUpload";
import Groups from "./home/Groups";
import Home from "./home/Home";
import PasswordReset from "./home/PasswordReset";
import SchoolGrades from "./home/SchoolGrades";
import InstallAppPage from "./InstallAppPage/InstallAppPage";
import LessonDetails from "./lesson/lessonRefactor/Lesson";
import MultipleDeviceNotice from "./MultipleDeviceNotice";
import Footer from "./nav-footer/Footer";
import Navbar from "./nav-footer/Navbar";
import NotFound from "./NotFound";
import NotificationsDrawer from "./notifications/NotificationsDrawer";
import Invoice from "./orders/Invoice";
import Invoices from "./orders/Invoices";
import Grade from "./quiz/Grade";
import Grades from "./quiz/Grades";
import LessonsList from "./teacher/LessonsList";

function App() {
  const portal = useAppSelector((state) => state.store.portal);
  const user = useAppSelector((state) => state.store.user);

  useCheckForUpdates({ remoteConfig: remoteConfig, key: "app_version" });
  useNotifications();

  useEffect(() => {
    console.log("Current location: ", window.location);
  }, [window.location.pathname]);

  useEffect(() => {
    console.log("Current Portal: ", portal);
  }, [portal]);

  useEffect(() => {
    console.log("Current User: ", user);
  }, [user]);

  // return (
  //   <div className="closed-for-updates container mx-5 my-2">
  //     <div className="d-flex">
  //       <img src={UPDATING} alt="updating" className="mx-auto" />
  //     </div>
  //     <div className="d-flex">
  //       <p className="h1 mx-auto">الموقع مغلق للتحديثات</p>
  //     </div>
  //     <div className="d-flex">
  //       <p className="h2 mx-auto">أعد المحاولة بعد ساعة</p>
  //     </div>
  //   </div>
  // );

  const checkVersionAndSW = async () => {
    try {
      const res = await fetch(
        `${endpoints.checkVersionAndSW}?rand_key=${Math.random()}`
      );
      const data: {
        current_app_version: string;
        remove_service_worker: boolean;
      } = (await res.json()).data;

      const currentAppVersionFromServerLocalStorage = localStorage.getItem(
        "current_app_version_from_server"
      );

      if (data?.remove_service_worker) {
        console.log("removing service worker");
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          registrations?.forEach((registration) => {
            registration?.unregister();
          });
        });
      }

      if (
        data?.current_app_version &&
        data?.current_app_version !== currentAppVersionFromServerLocalStorage
      ) {
        console.log("updating app to latest version");
        caches.keys().then((names) => {
          names.forEach(async (name) => {
            await caches.delete(name);
          });
          localStorage.setItem(
            "current_app_version_from_server",
            data?.current_app_version.toString()
          );
          location.reload();
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTeacherColorScheme(window.location.hostname);
    checkVersionAndSW();
  }, []);

  const { isAppInstalled } = usePWA();

  return (
    <BrowserRouter>
      <div className="App mobile-nav" style={{ overflowX: "hidden" }}>
        <Navbar />
        <NotificationsDrawer />
        <Animation>
          <div
            className="content"
            style={{ paddingBottom: isAppInstalled ? "5rem" : "1rem" }}
          >
            <Routes>
              {isTeacher() && (
                <Route path="/dashboard" element={<Dashboard />} />
              )}
              {!!user && (
                <>
                  <Route path="/account" element={<AccountPage />} />
                  <Route path="/grades/:id" element={<Grade />} />
                  <Route path="/grades" element={<Grades />} />
                  <Route path="/invoices/:id" element={<Invoice />} />
                  <Route path="/invoices" element={<Invoices />} />
                  <Route path="/school-grades" element={<SchoolGrades />} />
                  <Route path="/:schoolgrade/groups" element={<Groups />} />
                  <Route
                    path="/:schoolgrade/:group/lessons"
                    element={<LessonsList />}
                  />
                  <Route
                    path="/:schoolgrade/:group/:lesson"
                    element={<LessonDetails />}
                  />
                  <Route path="/drive" element={<FileUpload />} />
                  <Route path="/ai-assistant" element={<AiAssistant2 />} />
                  <Route path="/complete-info" element={<CompleteInfo />} />
                  <Route
                    path="/multiple-device-notice"
                    element={<MultipleDeviceNotice />}
                  />
                </>
              )}
              <Route path="/password-reset" element={<PasswordReset />} />
              <Route path="/install" element={<InstallAppPage />} />
              <Route path="/index.html" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/" element={<Home />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </Animation>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
