/**
 * This page is cloned from the invoices page so it might
 * have similar naming conventions to the invoices page
 *
 */

import { useRef, useState } from "react";
import { endpoints } from "../../hooks/endpoints";
import { getToken } from "../../hooks/utils";
import { useAppSelector } from "../../store/hooks";
import Animation from "../Animation";

const CouponCreation = () => {
  const [codes, setCodes] = useState();
  const [currentCat, setCurrentCat] = useState();
  const [finalCats, setFinalCats] = useState();
  const [lesson, setLesson] = useState();
  const [codeType, setCodeType] = useState();
  const [groups, setGroups] = useState();
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const [couponInfo, setCouponInfo] = useState(null);
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  const coupon = useRef();

  const countRef = useRef();
  const prefixRef = useRef();

  const { userProfileData, portal } = useAppSelector((state) => state.store);

  const lessons = portal.data;

  function downloadCouponData(couponsArray) {
    var blob = new Blob([couponsArray.join("\n")], { type: "text/plain" });
    var link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "coupons.txt";
    link.click();
  }
  const handleTypeChange = (e) => {
    setCodeType(e.target.value);
    setFinalCats(null);
    setLesson(null);
  };

  const handleCheckCoupon = (e) => {
    e.preventDefault();
    setIsCouponLoading(true);
    fetch(
      endpoints.checkCoupon +
        `?coupon=${(coupon.current as any).value}&timestamp=${Date()}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCouponInfo(data);
        setIsCouponLoading(false);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
        setIsCouponLoading(false);
      });
  };

  const getCodes = (e) => {
    e.preventDefault();
    setIsPending(true);

    let chosenCats = null;
    if (codeType === "category") {
      const formData = new FormData(e.target);
      const formDataObject = {};
      for (const [key, value] of formData.entries()) {
        formDataObject[key] = value;
      }
      const keysArray = Object.keys(formDataObject);
      if (keysArray.length > 0) {
        chosenCats = keysArray.join(",");
      }
    }

    fetch(endpoints.generateCodes, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      method: "POST",
      body: JSON.stringify({
        number_of_coupons: (countRef.current as any)?.value ?? 5,
        product_categories: chosenCats,
        product_id: lesson,
        coupon_prefix: (prefixRef.current as any)?.value ?? "",
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        downloadCouponData(res.data);
        setIsPending(false);
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Animation>
      <div className="m-3 p-3 bg-white rounded-ea shadow">
        <div className="table-responsive">
          <h3 className="mt-3 mb-4 bg-ea-primary p-3 text-white rounded-ea shadow fw-bold">
            إنشاء أكواد
          </h3>
          <form className="my-2" onSubmit={(e) => getCodes(e)}>
            <select
              onChange={handleTypeChange}
              className="form-select w-75 my-2"
              aria-label="Default select example"
              required
            >
              <option value="" selected>
                نوع الكود
              </option>
              <option value="category">يعمل على قسم بأكمله</option>
              <option value="product">يعمل على حصة محددة</option>
            </select>
            {portal.cats &&
              codeType === "category" &&
              portal.cats
                .filter((cat) => cat.parent !== 0)
                .map((cat) => (
                  <div className="form-check form-switch my-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={cat.term_taxonomy_id.toString()}
                      name={cat.term_taxonomy_id.toString()}
                    />
                    <label
                      className="form-check-label ms-1"
                      htmlFor={cat.term_taxonomy_id.toString()}
                    >
                      {cat.name.indexOf("|") !== -1
                        ? cat.name.split("|")[0]
                        : cat.name}
                    </label>
                  </div>
                ))}
            {codeType === "product" && (
              <select
                onChange={(e) => setLesson(e.target.value as any)}
                className="form-select w-75 my-2"
                aria-label="Default select example"
                required
              >
                <option disabled value="" selected>
                  إختر الحصص
                </option>
                {lessons &&
                  lessons.map((lesson) => (
                    <option value={lesson.ID}>
                      {lesson.post_title} - {lesson.slug.replace(/-/g, " ")}
                    </option>
                  ))}
              </select>
            )}
            <div className="form-floating mb-3">
              <input
                ref={prefixRef}
                type="text"
                className="form-control w-75 my-2"
                id="coupon-prefix"
                maxLength={2}
                placeholder="XX"
              />
              <label htmlFor="coupon-prefix">
                وضع رمز معين في جميع الآكواد (حرفين فقط)
              </label>
            </div>
            <div className="form-floating mb-3">
              <input
                ref={countRef}
                type="number"
                className="form-control w-75 my-2"
                id="amount-of-codes"
                max={500}
                min={1}
                placeholder="name@example.com"
              />
              <label htmlFor="amount-of-codes">
                عدد الأكواد (بحد أقصى 500 كود)
              </label>
            </div>
            {!isPending && (
              <input
                type="submit"
                className="btn ea-btn rounded-ea my-2"
                value="إنشاء"
              />
            )}
            {isPending && (
              <button
                className="btn ea-btn rounded-ea my-2"
                type="button"
                disabled
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                إنشاء...
              </button>
            )}
          </form>
          <div
            className="my-2 mb-3"
            style={{ borderBottom: "1px solid black" }}
          ></div>
          <div>
            <div className="mb-3">
              <h4>الكشف عن كود</h4>
              <input
                ref={coupon}
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="أكتب الكود هنا..."
              />
            </div>
            {!isCouponLoading ? (
              <button
                className="btn ea-btn rounded-ea"
                onClick={handleCheckCoupon}
              >
                بحث
              </button>
            ) : (
              <button className="btn ea-btn rounded-ea" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                بحث...
              </button>
            )}
            {couponInfo && couponInfo.found ? (
              <div
                style={{ width: "fit-content" }}
                className={`alert alert-${
                  couponInfo.usage.limit > couponInfo.usage.used
                    ? "info"
                    : "danger"
                } rounded-ea my-2`}
                role="alert"
              >
                <p>
                  <span className="fw-bold">الكود: </span>
                  <span>{couponInfo.code}</span>
                </p>
                <p>
                  <span className="fw-bold">الحالة: </span>
                  {couponInfo.usage.limit > couponInfo.usage.used ? (
                    <span className="text-success fw-bold">الكود ساري</span>
                  ) : (
                    <span className="text-danger fw-bold">الكود مستخدم</span>
                  )}
                </p>
                <p className="d-flex">
                  <span className="fw-bold">نطاق الكود: </span>
                  {[couponInfo.products, ...couponInfo.categories].map(
                    (title) =>
                      title && (
                        <span className="mx-1 border-ea px-2 rounded-ea">
                          {title.indexOf("|") > -1
                            ? title.split("|")[0]
                            : title}
                        </span>
                      )
                  )}
                </p>
                {(couponInfo.teacher_from_cat ||
                  couponInfo.teacher_from_lesson) &&
                  JSON.parse(
                    localStorage.getItem(`${window.location.hostname}_user`)
                  ).user_email === "mail.teacheracademy@gmail.com" && (
                    <p className="d-flex">
                      <span className="fw-bold">المدرس: </span>{" "}
                      {couponInfo.teacher_from_cat}
                      {couponInfo.teacher_from_lesson}
                    </p>
                  )}
                {couponInfo.customer && (
                  <p>
                    قام <span className="fw-bold">{couponInfo.customer}</span>{" "}
                    بإستخدام هذا الكود
                  </p>
                )}
              </div>
            ) : couponInfo && !couponInfo.found ? (
              <div
                style={{ width: "fit-content" }}
                className="alert alert-danger rounded-ea my-2"
                role="alert"
              >
                الكود غير صحيح
              </div>
            ) : (
              ""
            )}
          </div>
          {error && <h2>يجب تسجيل الدخول اولا</h2>}
        </div>
      </div>
    </Animation>
  );
};

export default CouponCreation;
