
const Notification = ({
  title,
  body,
  resetNotification,
}: {
  title: string;
  body: string;
  resetNotification: () => void;
}) => {
  return (
    <div className="notification-wrapper">
      <p className="notification-title">{title}</p>
      <p className="notification-body">{body}</p>
      <button className="notification-close" onClick={resetNotification}>
        x
      </button>
    </div>
  );
};

export default Notification;
