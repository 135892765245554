import useSendNotifications from "./useSendNotifications";

const SendNotification = () => {
  const { sendNotification, data, isPending, error, notificationRef } =
    useSendNotifications();
  return (
    <form
      className={`my-2 ${isPending ? "loading" : ""}`}
      onSubmit={(e) => {
        e.preventDefault();
        sendNotification();
      }}
    >
      <textarea
        ref={notificationRef}
        placeholder="محتوى الإشعار"
        className="w-100 rounded-ea p-2 my-2"
        maxLength={150}
        style={{ height: "fit-content", resize: "none" }}
        rows={2}
        required
      ></textarea>
      <div className="d-flex">
        <button
          type="submit"
          className="btn ea-btn rounded-ea py-2"
          style={{ height: "fit-content" }}
        >
          ارسال
        </button>
        {data && (
          <div
            style={{
              width: "fit-content",
              height: "auto",
            }}
            className="alert alert-success text-center mx-2 rounded-ea py-2"
            role="alert"
          >
            {data?.message}
          </div>
        )}
        {!!error && (
          <div
            style={{ width: "fit-content", height: "auto" }}
            className="alert alert-danger text-center mx-2 rounded-ea py-2"
            role="alert"
          >
            حدث خطأ , تواصل مع الدعم الفني
          </div>
        )}
      </div>
    </form>
  );
};

export default SendNotification;
