import { getToken, onMessage } from "firebase/messaging";
import { useEffect, useState } from "react";
import { ErrorTags, reportAppError } from "../errorReporter";
import { messaging } from "../firebaseConfig";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { addNotification } from "../store/slices/storeSlice";
import { endpoints } from "./endpoints";
import { getToken as getAuthToken, playNotificationSound } from "./utils";

const useNotifications = () => {
  const user = useAppSelector((state) => state.store.user);
  const [permission, setPermission] = useState(false);
  const dispatch = useAppDispatch();

  const submitFcmInfo = async ({ token, topic }) => {
    try {
      const res = await (
        await fetch(endpoints.submitFcmInfo, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthToken(),
          },
          body: JSON.stringify({
            token,
            topic,
          }),
        })
      ).json();

      if (res.status === 200) {
        localStorage.setItem("fcm_token", token);
        localStorage.setItem(`subscribed_to_fcm_${topic}`, "1");
      } else {
        throw new Error(res.message);
      }
    } catch (err) {
      console.error(err);
      reportAppError({
        e: err,
        info: err.message,
        errorTag: ErrorTags.SubmitFcmInfo,
      });
    }
  };

  function requestPermission() {
    console.log("Requesting permission...");
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification permission granted.");
        setPermission(true);
      } else {
        console.log("Unable to get permission to notify.");
      }
    });
  }

  useEffect(() => {
    onMessage(messaging, (payload) => {
      console.log('cewda', payload.data.portal, window.location.hostname)
      if (user && payload.data.portal === window.location.hostname) {
        console.info("Message received. ", payload);
        dispatch(
          addNotification({
            ID: new Date().getTime(),
            title: payload.notification.title,
            body: payload.notification.body,
          })
        );
        playNotificationSound();
      } else {
        new Notification(payload.notification.title, {
          body: payload.notification.body,
        });
      }
    });

    try {
      getToken(messaging, {
        vapidKey:
          "BCimMc2GrI7MZ77gLNfr7IMUiXXUrqYVxCNrLifT7nsiiBJpYN1eoiAnZLe0jKEmar3lCm7jC8phNN3CrHBiszs",
      }).then((currentToken) => {
        if (currentToken) {
          const topic = window.location.hostname;
          console.info("token: " + currentToken);

          if (localStorage.getItem(`subscribed_to_fcm_${topic}`) !== "1") {
            submitFcmInfo({
              token: currentToken,
              topic,
            });
          }

          if (localStorage.getItem(`subscribed_to_fcm_global`) !== "1") {
            submitFcmInfo({
              token: currentToken,
              topic: "global",
            });
          }
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          throw new Error("No registration token available.");
        }
      });
    } catch (err) {
      console.error("An error occurred while retrieving token. ", err);
      requestPermission();
    }
  }, [permission, user]);

  return null;
};

export default useNotifications;
